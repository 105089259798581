import React, { useState, useEffect, useRef } from "react";

import Checklists from "../../Trello/components/Checklists";
import HeaderTabs from "./Shoot Tab Components/HeaderTabs";
// import Fab from "./Shoot Tab Components/Fab";
import TabPanel from "./Shoot Tab Components/TabPanel";
import EmailModal from "../Email Modal/EmailModal";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import ShootInfo from "./Shoot Tab Components/ShootInfo";
import Invoicing from "./Shoot Tab Components/Invoicing";
import JobInfo from "./Shoot Tab Components/JobInfo";
import Outsourcers from "./Shoot Tab Components/Outsourcers";
import Attachments from "./Shoot Tab Components/Attachments";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import URLModal from "../../GlobalComponents/URLModal";

import { createGlobalStyle } from "styled-components";
import { use100vh } from "react-div-100vh";

import { useAtom } from "jotai";
import {
  themeAtom,
  listsAtom,
  stylingListsAtom,
  isElectronAtom,
  isShootTabAtom,
  emailSelectorDialogOpenAtom,
} from "../../-Atoms";

import ConfettiExplosion from "react-confetti-explosion";

import LabelChip from "../../GlobalComponents/LabelChip";
import PermanentLabelChip from "../../GlobalComponents/PermanentLabelChip";

import DueDateChip from "../../GlobalComponents/DueDateChip";
import ImagesChip from "../../GlobalComponents/ImagesChip";

// import { getAnalytics, logEvent } from "firebase/analytics";

import { v4 as uuidv4 } from "uuid";

// import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CircularProgress from "@mui/material/CircularProgress";
import MarkdownNotesEditor from "./Shoot Tab Components/MarkdownNotesEditor";
import People from "./Shoot Tab Components/People";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LabelMenu from "./Shoot Tab Components/LabelMenu";
import EmailSelectorDialog from "./Shoot Tab Components/EmailSelectorDialog";

import getJobName from "../../GlobalFunctions/getJobName";

import { getDropboxUrlWithRetry } from "../../DropboxFunctions";

const { DateTime } = require("luxon");

const GlobalStyles = createGlobalStyle`
  .MuiSpeedDialAction-staticTooltipLabel {
    min-width: 300px !important;
  }
  
`;
// html {
//   overflow-x: hidden;
// }
const ShootTab = ({
  firebase,
  // jobDataInit,
  jobData,
  setJobData,
  clientDataInit,
  staffData,
  modalWidth,
  isMobile,
  setStopModalClose,
  kanbanView,
  element,
  soloClose,
  modalJobData,
  modalClientData,
  layoutView,
}) => {
  // console.log(setStopModalClose)
  // console.log(clientDataInit)
  // console.log(modalClientData)
  const [theme] = useAtom(themeAtom);
  const [lists, setLists] = useAtom(listsAtom);
  const [stylingLists] = useAtom(stylingListsAtom);
  const [isElectron] = useAtom(isElectronAtom);
  const [, setIsShootTab] = useAtom(isShootTabAtom);

  const [emailSelectorDialogOpen, setEmailSelectorDialogOpen] = useAtom(
    emailSelectorDialogOpenAtom
  );
  const handleEmailSelectorDialogClose = () => {
    setEmailSelectorDialogOpen(false);
  };
  // const [jobData, setJobData] = useState(jobDataInit);
  const [clientData, setClientData] = useState(clientDataInit);
  useEffect(() => {
    setIsShootTab(true);

    return () => {
      setIsShootTab(false);
    };
  });

  useEffect(() => {
    if (!modalClientData.id) {
      setClientData(clientDataInit);
    }
  }, [clientDataInit, modalClientData]);

  useEffect(() => {
    let unsub;
    if (modalJobData.id) {
      unsub = firebase
        .firestore()
        .collection("JobData")
        .doc(modalJobData.id)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setJobData(data);
        });
    }
    return () => {
      try {
        unsub();
      } catch (e) {}
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let unsub;
    if (modalClientData.id) {
      unsub = firebase
        .firestore()
        .collection("ClientDatabase")
        .doc(modalClientData.id)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setClientData(data);
        });
    }
    return () => {
      try {
        unsub();
      } catch (e) {}
    };
  }, [modalClientData, firebase, setClientData]);

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailKind, setEmailKind] = useState("");

  const [tabState, setTabState] = useState("ShootInfo");
  const handleTabStateChange = (event, newValue) => {
    setTabState(newValue);
  };

  const height = use100vh();
  const [isExploding, setIsExploding] = useState(false);

  const [labelMenuOpen, setLabelMenuOpen] = useState(false);

  const labelsContainer = useRef(null);

  const [idList, setIdList] = useState("");

  // eslint-disable-next-line
  const [idStylingList, setIdStylingList] = useState("");

  const [shouldCreateSession, setShouldCreateSession] = useState(isElectron);

  const [startShootingButtonText, setStartShootingButtonText] =
    useState("Start Shooting");

  useEffect(() => {
    let tmpId = "";
    lists.forEach((list) => {
      if (list.items.indexOf(jobData.id) !== -1) {
        if (!tmpId) {
          tmpId = list.id;
          setIdList(list.id);
        } else {
          alert(
            "List Conflict: Job is in 2 Lists, this shouldn't be possible. Please screenshot this (including the job number) and send it to Alex. The first list to be found is what will be used."
          );
        }
      }
    });

    return () => {
      setIdList("");
    };
  }, [lists, jobData, setIdList]);

  useEffect(() => {
    let tmpId = "";
    stylingLists.forEach((list) => {
      if (list.items.indexOf(jobData.id) !== -1) {
        if (!tmpId) {
          tmpId = list.id;
          setIdStylingList(list.id);
        } else {
          alert(
            "List Conflict: Job is in 2 Lists, this shouldn't be possible. Please screenshot this (including the job number) and send it to Alex. The first list to be found is what will be used."
          );
        }
      }
    });

    return () => {
      setIdStylingList("");
    };
  }, [stylingLists, jobData, setIdStylingList]);

  const [URLModalOpen, setURLModalOpen] = useState(false);
  const [urlModalUrl, setUrlModalUrl] = useState("");

  const [contextMenu, setContextMenu] = useState(null);
  const handleClose = () => {
    setContextMenu(null);
  };
  const handleContextMenu = (event) => {
    event.preventDefault();
    if (contextMenu) {
      setContextMenu(null);
    } else {
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
    }
  };

  const createSession = () => {
    handleClose();
    if (!isElectron) return;
    window.ipcRenderer?.send("createSession", getJobName(jobData));
  };

  const regenerateJobName = () => {
    handleClose();
    const jts = jobData.jobTypeFull.match(/^.*\((-.*)\)/)[1];
    const jn = `${jobData.jobNumber}-${jobData.client}${
      jobData.multipleDays ? `-${jobData.multipleDays}` : ""
    }${jts}`;

    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .set({ jobName: jn || null }, { merge: true });
  };

  const [openOperatorChecklistModal, setOpenOperatorChecklistModal] =
    useState(false);

  const [openJobNumberDialog, setOpenJobNumberDialog] = useState(false);

  const handleJobNumberDialogClose = () => {
    setOpenJobNumberDialog(false);
  };
  const [startShootingDisabled, setStartShootingDisabled] = useState(false);

  const startShooting = async () => {
    try {
      if (!jobData.jobNumber && jobData.multipleDays) {
        setOpenJobNumberDialog(true);
        return;
      }
      if (!jobData.jobNumber) {
        if (startShootingDisabled) return;
        setStartShootingDisabled(true);
        const jobYear = new Date(jobData.bookedStart).getFullYear();
        setStartShootingButtonText("Getting Job Number...");
        const res = await fetch(
          "https://us-central1-its-connect-main.cloudfunctions.net/getLatestJobNumber",
          {
            method: "POST",
            mode: "cors",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ year: jobYear }),
          }
        ).then(function (res) {
          return res.json();
        });
        jobData.jobNumber = res.jobNumber;
        const jtsRaw = jobData.jobTypeFull.match(/^.*\((-.*)\)/);
        let jts = "";
        if (jtsRaw) {
          jts = jtsRaw[1];
        }
        const jn = `${parseInt(res.jobNumber)}-${jobData.client}${
          jobData.multipleDays ? `-${jobData.multipleDays}` : ""
        }${jts}`;
        firebase
          .firestore()
          .collection("JobData")
          .doc(jobData.id)
          .update({ jobName: jn }, { merge: true });
        firebase
          .firestore()
          .collection("JobData")
          .doc(jobData.id)
          .update({ jobNumber: parseInt(res.jobNumber) }, { merge: true });
      }

      setStartShootingButtonText(
        <CircularProgress size={42} style={{ color: "#f7f7f7" }} />
      );

      // if (jobData.imageBreakdown) jobData.imageBreakdown = [];
      // console.log(jobData.imageBreakdown);
      const breakdown = [];

      if (clientData.imageBreakdown) {
        clientData.imageBreakdown.forEach((bri) => {
          const newitemObj = {
            id: bri.id,
            jobTypesFilter: bri.jobTypesFilter || [],
            description: bri.description,
            quantityProcessed: "",
            filesInPost: "",
            quantitySupplied: "",
            expectedPerHour: bri.expectedPerHour,
            costPerFile: bri.costPerFile,
            existingItem: true,
            itemHidden: false,
          };
          if (
            !jobData.imageBreakdown?.some((obj) => obj.id === newitemObj.id)
          ) {
            breakdown.push(newitemObj);
          }
        });
      }

      if (breakdown.length === 0 && jobData.imageBreakdown?.length === 0) {
        breakdown.push({
          id: uuidv4(),
          description: "",
          jobTypesFilter: [],
          quantityProcessed: "",
          filesInPost: "",
          quantitySupplied: "",
          expectedPerHour: "",
          costPerFile: "",
          existingItem: false,
          itemHidden: false,
        });
      }

      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update(
          {
            imageBreakdown: [...(jobData.imageBreakdown || []), ...breakdown],
            backgroundRolls: [],
            catering: [],
          },
          { merge: true }
        )
        .catch((error) => {
          console.log(error);
        });

      lists[0].items.push(jobData.id);

      const noDupes = [...new Set(lists[0].items)];
      lists[0].items = noDupes;

      await firebase
        .firestore()
        .collection("AdminCollection")
        .doc("lists")
        .update({ lists: lists })
        .catch((error) => {
          console.log(error);
        })
        .catch((error) => {
          console.log(error);
        });

      const newDueDate = new Date(jobData.dueDate);
      newDueDate.setHours(17);
      newDueDate.setMinutes(30);

      if (jobData.express) {
        if (!jobData?.idLabels.includes("5f0e8630fb83455e28da9908")) {
          jobData?.idLabels.push("5f0e8630fb83455e28da9908");
        }
        firebase
          .firestore()
          .collection("JobData")
          .doc(jobData.id)
          .update({ idLabels: jobData.idLabels })
          .catch((error) => {
            console.log(error);
          });
      }

      setStartShootingButtonText("Setup Finished");

      setTimeout(() => {
        setStartShootingButtonText("Start Shooting");
      }, 10000);

      ////////// DROPBOX SECTION //////////
      if (jobData.jobTypeShort.includes("-P")) {
        const productStoryboardsPath = `/${
          jobData.client
        }/-Product Storyboards/${jobData.jobNumber}-${jobData.client}${
          jobData.jobTypeShort
        }${jobData.multipleDays ? `/${jobData.multipleDays}` : ``}`;
        getDropboxUrlWithRetry(`${productStoryboardsPath}`);
      } else if (jobData.jobTypeShort.includes("-L")) {
        const basePath = `/${jobData.client}/${jobData.jobNumber}-${
          jobData.client
        }${jobData.jobTypeShort}${
          jobData.multipleDays ? `/${jobData.multipleDays}` : ``
        }`;
        const lifeStoryboardsPath = `${basePath}/Storyboards`;
        const lifeStoryboardSelectsPath = `${basePath}/Storyboard Selects`;
        getDropboxUrlWithRetry(`${lifeStoryboardsPath}`);
        getDropboxUrlWithRetry(`${lifeStoryboardSelectsPath}`);
      }

      // getDropboxUrlWithRetry

      ////////// DROPBOX SECTION //////////

      ////////// ELECTRON SECTION //////////
      if (!isElectron) return;
      if (shouldCreateSession) {
        window.ipcRenderer?.send("createSession", {
          jobName: getJobName(jobData),
          cardID: jobData.id,
        });
        firebase
          .firestore()
          .collection("JobData")
          .doc(jobData.id)
          .update({
            isInShooting: true,
            isInShootingTime: DateTime.now()
              .setZone("Australia/Sydney")
              .startOf("day")
              .toUTC()
              .toISO(),
          })
          .catch((error) => {
            console.log(error);
          });
        setStartShootingDisabled(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // function ensureSettingsFile() {
  //   try {
  //     if (isElectron) {
  //       console.log("ensureSettingsFile");
  //       window.ipcRenderer.send(
  //         "eval",
  //         `try{
  //       if (isCaptureOpen) {
  //         const sessionName = applescript(
  //                 \`try
  //               if application "Capture One 22" is running then
  //               tell application "Capture One 22" to return name of current document
  //               else
  //               return false
  //             end if
  //             on error
  //             return false
  //             end try\`
  //               ).replace(".cosessiondb", "");
  //               if(sessionName === "${getJobName(jobData)}"){
  //                 createSettingsFile("${jobData.id}")
  //             }
  //       }
  //     } catch(e){}`
  //       );
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // useEffect(() => {
  //   ensureSettingsFile();
  //   let interval = setInterval(ensureSettingsFile, 10000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const moveList = async (e) => {
    if (
      e.target.value === "5f081766578ced03d01ff5e8" ||
      e.target.value === "5f08177048ef1f1d1dc001b6" ||
      e.target.value === "5f08177905473c1e626dea40"
    ) {
      setIsExploding(true);
      setTimeout(() => {
        setIsExploding(false);
      }, 3000);
    }

    if (e.target.value === "0367cb77-644f-4d57-b4e8-4286148218d7") {
      if (jobData.idLabels.indexOf("6285ea079131e28982c19d39") === -1) {
        jobData.idLabels.push("6285ea079131e28982c19d39");
      }
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ idLabels: jobData.idLabels });
    }

    if (e.target.value === "bbb73c17-6690-4d43-8569-be12b0d5ee27") {
      if (jobData.idLabels.indexOf("5e6881d6af988c41f2bf6e14") === -1) {
        jobData.idLabels.push("5e6881d6af988c41f2bf6e14");
      }
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ idLabels: jobData.idLabels });
    }

    lists.forEach((list) => {
      if (list.items.indexOf(jobData.id) !== -1) {
        list.items.splice(list.items.indexOf(jobData.id), 1);
      }
      if (list.id === e.target.value) {
        if (!list.items.includes(jobData.id)) {
          list.items.push(jobData.id);
        }
      }
    });

    lists.forEach((list) => {
      const noDupes = [...new Set(list.items)];
      list.items = noDupes;
    });

    await firebase
      .firestore()
      .collection("AdminCollection")
      .doc("lists")
      .update({ lists: lists });

    if (e.target.value === "5f081763cd97556d03075c86") {
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({
          isInShooting: true,
          isInShootingTime: DateTime.now()
            .setZone("Australia/Sydney")
            .startOf("day")
            .toUTC()
            .toISO(),
        });
    } else {
      firebase.firestore().collection("JobData").doc(jobData.id).update({
        isInShooting: false,
        isInShootingTime: null,
      });
    }
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [alertEvent] = useState({});
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  // console.log(!jobData?.jobTypeFull?.includes("-V"));

  const deleteCard = () => {
    handleClose();
    lists.forEach((list) => {
      if (list.items.indexOf(jobData.id) !== -1) {
        list.items.splice(list.items.indexOf(jobData.id), 1);
      }
    });
    setLists(lists);
    firebase
      .firestore()
      .collection("AdminCollection")
      .doc("lists")
      .update({ lists: lists });
  };
  return (
    <div style={{ maxWidth: 780 }}>
      <AlertDialog
        open={openAlert}
        onClose={handleCloseAlert}
        alertEvent={alertEvent}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {isElectron ? (
          <>
            <MenuItem onClick={createSession}>Create Session</MenuItem>
            <MenuItem onClick={deleteCard}>Delete Card</MenuItem>
            <MenuItem
              onClick={() => {
                window.ipcRenderer.send(
                  "eval",
                  `if (isCaptureOpen) {
                    createSettingsFile("${jobData.id}")
                  }`
                );
                handleClose();
              }}
            >
              Fix Notes Shortcut
            </MenuItem>
          </>
        ) : null}
        <MenuItem onClick={regenerateJobName}>Fix Job Name</MenuItem>
      </Menu>
      <JobNumberErrorDialog
        open={openJobNumberDialog}
        handleClose={handleJobNumberDialogClose}
      />
      <URLModal
        open={URLModalOpen}
        handleClose={() => {
          setURLModalOpen(false);
          if (!kanbanView) {
            window.ipcRenderer?.send("setShootWindowBounds");
          }
        }}
        url={urlModalUrl}
      />
      <EmailSelectorDialog
        open={emailSelectorDialogOpen}
        handleClose={handleEmailSelectorDialogClose}
        jobData={jobData}
        setEmailModalOpen={setEmailModalOpen}
        setEmailKind={setEmailKind}
        setOpenOperatorChecklistModal={setOpenOperatorChecklistModal}
        firebase={firebase}
      />
      <div
        style={{
          background: theme === "dark" ? "#1f1f1f" : "#ffffff",
        }}
      >
        {idList ? (
          <>
            <div
              style={{
                marginTop: -10,
                position: kanbanView ? "relative" : "inherit",
                overflowY: kanbanView ? "auto" : "inherit",
                height: "100vh",
              }}
            >
              <GlobalStyles />
              <LabelMenu
                firebase={firebase}
                jobData={jobData}
                open={labelMenuOpen}
                setOpen={setLabelMenuOpen}
                labelsContainer={labelsContainer}
              />

              <div
                style={{
                  marginTop: layoutView ? 20 : 0,
                  marginBottom: 10,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  background: theme === "dark" ? "#1f1f1f" : "#ffffff",
                  // "-webkit-app-region": "drag",
                  // WebkitAppRegion: "drag",
                }}
                className="stickyClientName"
                onClick={() => {
                  navigator.clipboard.writeText(jobData.id);
                }}
              >
                <span onContextMenu={handleContextMenu}>
                  {jobData ? getJobName(jobData) : null}
                </span>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <DueDateChip
                    firebase={firebase}
                    dueDate={jobData.dueDate}
                    complete={jobData.complete}
                    expressDueDate={jobData.expressDueDate}
                    expressComplete={jobData.expressComplete}
                    id={jobData.id}
                    theme={theme}
                    canEdit={true}
                    flexAlign="flex-end"
                  />
                  <div style={{ marginLeft: 10, marginTop: 0 }}>
                    <ImagesChip jobData={jobData} clientData={clientData} />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    margin: 10,
                  }}
                  ref={labelsContainer}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                      width: "80%",
                    }}
                  >
                    {jobData.idLabelsPermanent &&
                      jobData.idLabelsPermanent.map((label, index) => (
                        <PermanentLabelChip
                          key={`${label}-${index}`}
                          label={label}
                          kanbanBoard={false}
                          jobData={jobData}
                          firebase={firebase}
                        />
                      ))}
                    {jobData.idLabels &&
                      jobData.idLabels.map((label, index) => (
                        <LabelChip
                          key={`${label}-${index}`}
                          label={label}
                          setLabelMenuOpen={setLabelMenuOpen}
                          kanbanBoard={false}
                        />
                      ))}
                    <Chip
                      label={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AddIcon sx={{ fontSize: 16, marginRight: 1 }} />
                          Add Label
                        </span>
                      }
                      onClick={() => setLabelMenuOpen(true)}
                      size="small"
                      sx={{
                        margin: 0.4,
                        borderRadius: 0.6,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {isExploding && (
                  <ConfettiExplosion
                    particleCount={140}
                    particleSize={15}
                    height={"120vh"}
                    duration={2200}
                    zIndex={9999}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                  width: "100%",
                }}
              >
                <FormControl
                  sx={{ width: isMobile ? "96vw" : "758px" }}
                  fullWidth
                  variant="filled"
                >
                  <InputLabel id="List">List</InputLabel>
                  <Select
                    disableUnderline
                    labelId="List"
                    size="small"
                    value={idList}
                    onChange={async (e) => {
                      console.log(idList, e.target.value);
                      if (idList === "5f081763cd97556d03075c86") {
                        if (
                          e.target.value ===
                          "278845b5-da4e-40d1-8104-e8bb747439e1"
                        ) {
                          moveList(e);
                        } else {
                          moveList(e);
                          // setOpenAlert(true);
                          // setAlertEvent(e);
                        }
                      } else {
                        moveList(e);
                      }
                    }}
                  >
                    {lists &&
                      lists.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ marginTop: 0, width: "100%" }}>
                <HeaderTabs
                  tabState={tabState}
                  handleTabStateChange={handleTabStateChange}
                  theme={theme}
                  modalWidth={modalWidth}
                  idList={idList}
                />
                <TabPanel value={tabState} index="ShootInfo">
                  <ShootInfo
                    key="ShootInfo"
                    jobData={jobData}
                    clientData={clientData}
                    staffData={staffData}
                    findStaff={findStaff}
                    firebase={firebase}
                    isDarkMode={theme === "dark" ? true : false}
                    modalWidth={modalWidth}
                    isMobile={isMobile}
                    openOperatorChecklistModal={openOperatorChecklistModal}
                    setOpenOperatorChecklistModal={
                      setOpenOperatorChecklistModal
                    }
                  />
                </TabPanel>
                <TabPanel value={tabState} index="Invoicing">
                  <Invoicing
                    key="Invoicing"
                    jobData={jobData}
                    clientData={clientData || clientDataInit}
                    staffData={staffData}
                    findStaff={findStaff}
                    firebase={firebase}
                    isDarkMode={theme === "dark" ? true : false}
                    modalWidth={modalWidth}
                    isMobile={isMobile}
                    setStopModalClose={setStopModalClose}
                  />
                </TabPanel>
                <TabPanel value={tabState} index="People">
                  <People
                    jobData={jobData}
                    clientData={clientData}
                    theme={theme}
                    firebase={firebase}
                  />
                </TabPanel>
                <TabPanel value={tabState} index="JobInfo">
                  <JobInfo
                    key="JobInfo"
                    jobData={jobData}
                    clientData={clientData}
                    staffData={staffData}
                    findStaff={findStaff}
                    firebase={firebase}
                    isDarkMode={theme === "dark" ? true : false}
                    modalWidth={modalWidth}
                    isMobile={isMobile}
                  />
                </TabPanel>
                <TabPanel value={tabState} index="Outsourcers">
                  <Outsourcers
                    key="Outsourcers"
                    jobData={jobData}
                    clientData={clientData}
                    staffData={staffData}
                    findStaff={findStaff}
                    firebase={firebase}
                    isDarkMode={theme === "dark" ? true : false}
                    modalWidth={modalWidth}
                    isMobile={isMobile}
                    setEmailModalOpen={setEmailModalOpen}
                    setEmailKind={setEmailKind}
                  />
                </TabPanel>
                <Attachments
                  jobData={jobData}
                  clientData={clientData}
                  staffData={staffData}
                  firebase={firebase}
                  isDarkMode={theme === "dark" ? true : false}
                  setStopModalClose={setStopModalClose}
                  setUrlModalUrl={setUrlModalUrl}
                  setURLModalOpen={setURLModalOpen}
                />
                <div style={{ paddingBottom: 120 }}>
                  <Checklists
                    jobData={jobData}
                    clientData={clientData}
                    staffData={staffData}
                    firebase={firebase}
                    isDarkMode={theme === "dark" ? true : false}
                    setStopModalClose={setStopModalClose}
                    setUrlModalUrl={setUrlModalUrl}
                    setURLModalOpen={setURLModalOpen}
                    idList={idList}
                  />
                </div>

                {/* <div style={{ marginTop: 140 }}></div> */}

                <Modal
                  open={emailModalOpen}
                  onClose={() => {
                    setEmailModalOpen(false);
                    setEmailKind("");
                  }}
                  closeAfterTransition
                >
                  <Slide
                    direction="up"
                    in={emailModalOpen}
                    mountOnEnter
                    unmountOnExit
                    timeout={200}
                  >
                    <div
                      id="emailModalBG"
                      style={{
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: height,
                      }}
                      onClick={(e) => {
                        if (e.target.id === "emailModalBG") {
                          setEmailModalOpen(false);
                          setEmailKind("");
                        }
                      }}
                    >
                      <div
                        style={{
                          width: isMobile ? "97vw" : "97vw",
                          height: "97vh",
                          background:
                            theme === "dark" ? "#1f1f1f   " : "#ffffff",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          borderRadius: 10,
                        }}
                      >
                        <EmailModal
                          firebase={firebase}
                          theme={theme}
                          width={isMobile ? "97vw" : "97vw"}
                          emailKind={emailKind}
                          jobData={jobData}
                          clientData={clientData}
                          setEmailModalOpen={setEmailModalOpen}
                          isMobile={isMobile}
                        />
                      </div>
                    </div>
                  </Slide>
                </Modal>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                height: "88vh",
                marginTop: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {!kanbanView && !idList ? (
                <>
                  <Button
                    variant="contained"
                    onClick={startShooting}
                    sx={{ width: 280, height: 70, fontSize: 18 }}
                  >
                    {startShootingButtonText}
                  </Button>
                  {clientData.spreadsheetURL ? (
                    <Button
                      variant="outlined"
                      sx={{ marginTop: 2 }}
                      onClick={() => {
                        window.open(clientData.spreadsheetURL, "_blank");
                      }}
                    >
                      Shot List
                    </Button>
                  ) : null}
                  {/* {isElectron ? (
                    !jobData?.jobTypeFull?.includes("-V") ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              jobData?.jobTypeFull?.includes("-V")
                                ? false
                                : shouldCreateSession
                            }
                            onChange={(e) => {
                              setShouldCreateSession(e.target.checked);
                            }}
                          />
                        }
                        label="Create Session"
                      />
                    ) : null
                  ) : null} */}
                  {isElectron ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            jobData?.jobTypeFull?.includes("-V")
                              ? false
                              : shouldCreateSession
                          }
                          onChange={(e) => {
                            setShouldCreateSession(
                              jobData?.jobTypeFull?.includes("-V")
                                ? false
                                : e.target.checked
                            );
                          }}
                        />
                      }
                      label="Create Session"
                    />
                  ) : null}

                  <div
                    style={{
                      marginTop: 70,
                      maxHeight: 500,
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        overflowY: "auto",
                        height: "100%",
                        width: 480,
                      }}
                    >
                      {jobData.photographerNotes ||
                      clientData.photographerClientNotes ? (
                        <MarkdownNotesEditor
                          label={
                            jobData.jobTypeFull?.includes("-V") ||
                            jobData.jobTypeFull?.includes("-E")
                              ? "Videographer Notes"
                              : "Photographer Notes"
                          }
                          jobValue={jobData.photographerNotes}
                          setJobValue={() => {}}
                          clientValue={clientData.photographerClientNotes}
                          isDarkMode={theme === "dark"}
                          startShooting={true}
                        />
                      ) : null}
                      {jobData.operatorNotesjobData ||
                      clientData.operatorClientNotes ? (
                        <MarkdownNotesEditor
                          label={
                            jobData.jobTypeFull?.includes("-V") ||
                            jobData.jobTypeFull?.includes("-E")
                              ? "Video Assistant Notes"
                              : "Operator Notes"
                          }
                          jobValue={jobData.operatorNotesjobData}
                          setJobValue={() => {}}
                          clientValue={clientData.operatorClientNotes}
                          isDarkMode={theme === "dark"}
                          startShooting={true}
                        />
                      ) : null}
                      {jobData.stylistNotes || clientData.stylistClientNotes ? (
                        <MarkdownNotesEditor
                          label="Stylist Notes"
                          jobValue={jobData.stylistNotes}
                          setJobValue={() => {}}
                          clientValue={clientData.stylistClientNotes}
                          isDarkMode={theme === "dark"}
                          startShooting={true}
                        />
                      ) : null}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    width: modalWidth,
                    height: "100vh",
                    marginTop: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  Loading...
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ShootTab;

function AlertDialog({
  open,
  onClose,
  alertEvent,
  setDueDate,
  updateFS,
  jobData,
  firebase,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Double check the list?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The list you have selected is not Prep To Send, is this correct?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Oops I'll change that!
        </Button>
        <Button
          onClick={() => {
            // setDueDate(alertDueDate);
            // updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
            // onClose();
          }}
          autoFocus
        >
          Yep that's correct!
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const findStaff = (option, staffData) => {
  if (option?.preferredName)
    return `${option.preferredName} ${option.lastName}`;
  if (option?.firstName) return `${option.firstName} ${option.lastName}`;
  for (let i = 0; i < staffData.length; i++) {
    if (staffData[i].id === option) {
      if (staffData[i].preferredName)
        return `${staffData[i].preferredName} ${staffData[i].lastName}`;
      return `${staffData[i].firstName} ${staffData[i].lastName}`;
    }
  }
  return option;
};

const JobNumberErrorDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Job Number Error</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The Job Number for this job has not been set.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: 2 }}>
          Normally this wouldn't be a problem and I would just automatically
          make a new one. However as this is a multiple day job it needs to be
          set manually.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: 2 }}>
          You can either set the Job Number manually in the "Job" tab if you
          know what it should be, or speak to Bookings / Production about
          setting the Job Number.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Okie Dokie
        </Button>
      </DialogActions>
    </Dialog>
  );
};
