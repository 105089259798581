import React, { useState, useRef, memo } from "react";
import LockIcon from "@mui/icons-material/Lock";
import ArrowRight from "@mui/icons-material/ArrowRight";
import {
  Button,
  CircularProgress,
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";

const CheckboxCell = memo(
  ({ checked, onChange, model, handleRightClick }) => {
    const containerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    };

    return (
      <div
        style={containerStyle}
        onContextMenu={(event) => {
          handleRightClick(event, model);
        }}
      >
        <Checkbox
          sx={{ color: "#666666" }}
          checked={checked}
          onChange={onChange}
          size="small"
        />
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.checked === nextProps.checked
);

const WrapTextCell = memo(({ text, model, handleRightClick }) => {
  const cellStyle = {
    whiteSpace: "normal",
    wordBreak: "break-all",
    wordWrap: "break-word",
    lineHeight: "1.4",
    padding: "4px 12px",
    height: "100%",
    display: "flex",
    alignItems: "center",
  };

  return (
    <div
      style={cellStyle}
      onContextMenu={(event) => {
        handleRightClick(event, model);
      }}
    >
      {text}
    </div>
  );
});

const ShotCell = memo(({ index, model, handleRightClick }) => {
  const cellStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "4px",
  };

  return (
    <div
      style={cellStyle}
      onContextMenu={(event) => {
        handleRightClick(event, model);
      }}
    >
      {index + 1}
    </div>
  );
});

const resizeImage = (file, width) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (event) => {
      img.src = event.target.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const scaleFactor = width / img.width;
      canvas.width = width;
      canvas.height = img.height * scaleFactor;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        0.5
      );
    };

    img.onerror = reject;
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const ImageReferenceCell = memo(
  ({
    id,
    imageReference,
    onImageUpload,
    firebase,
    jobID,
    model,
    handleRightClick,
  }) => {
    const fileInputRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleUploadClick = () => {
      fileInputRef.current.click();
    };

    const uploadFile = async (file) => {
      setIsUploading(true);
      try {
        const resizedImageBlob = await resizeImage(file, 300);
        const fileName = `${id}_${file.name}`;
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `SheetData/${jobID}/shotlist_images/${fileName}`
        );

        const uploadTaskSnapshot = await fileRef.put(resizedImageBlob);

        const url = await uploadTaskSnapshot.ref.getDownloadURL();

        if (url) {
          onImageUpload(url);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsUploading(false);
      }
    };

    const handleFileChange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        await uploadFile(file);
      }
    };

    const handleDrop = async (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (file && file.type.startsWith("image/")) {
        await uploadFile(file);
      }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const containerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      padding: "4px",
    };

    const imgStyle = {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
      borderRadius: "2px",
    };

    return (
      <div
        style={containerStyle}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onContextMenu={(event) => {
          handleRightClick(event, model);
        }}
      >
        {isUploading ? (
          <CircularProgress size={24} />
        ) : imageReference ? (
          <img src={imageReference} alt="Reference" style={imgStyle} />
        ) : (
          <Button
            variant="contained"
            sx={{ fontSize: "0.8em" }}
            onClick={handleUploadClick}
          >
            Upload or Drop Image
          </Button>
        )}
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.imageReference === nextProps.imageReference
);

const FileNameCell = memo(({ text, model, handleRightClick, zoomLevel }) => {
  const cellStyle = {
    position: "relative",
    whiteSpace: "normal",
    wordBreak: "break-all",
    wordWrap: "break-word",
    lineHeight: "1.4",
    padding: "4px 12px",
    height: "100%",
    display: "flex",
    alignItems: "center",
  };

  const lockIconStyle = {
    position: "absolute",
    top: 8,
    right: 8,
    fontSize: Math.max(12, 20 * zoomLevel),
    opacity: 0.6,
  };

  return (
    <div
      style={cellStyle}
      onContextMenu={(event) => {
        handleRightClick(event, model);
      }}
    >
      {text}
      {model.locked && <LockIcon style={lockIconStyle} />}
    </div>
  );
});

const NestedMenuItem = React.forwardRef((props, ref) => {
  const {
    parentMenuOpen,
    label,
    rightIcon = <ArrowRight style={{ fontSize: 16 }} />,
    keepopen,
    children,
    customTheme,
    className,
    tabIndex: tabIndexProp,
    ContainerProps: ContainerPropsProp = {},
    rightAnchored,
    ...MenuItemProps
  } = props;

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = React.useRef(null);
  React.useImperativeHandle(ref, () => menuItemRef.current);

  const containerRef = React.useRef(null);
  React.useImperativeHandle(containerRefProp, () => containerRef.current);

  const menuContainerRef = React.useRef(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);

  const handleMouseEnter = (event) => {
    setIsSubMenuOpen(true);

    if (ContainerProps?.onMouseEnter) {
      ContainerProps.onMouseEnter(event);
    }
  };

  const handleMouseLeave = (event) => {
    setIsSubMenuOpen(false);

    if (ContainerProps?.onMouseLeave) {
      ContainerProps.onMouseLeave(event);
    }
  };

  const isSubmenuFocused = () => {
    const active = containerRef.current?.ownerDocument?.activeElement;

    for (const child of menuContainerRef.current?.children ?? []) {
      if (child === active) {
        return true;
      }
    }
    return false;
  };

  const handleFocus = (event) => {
    if (event.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }

    if (ContainerProps?.onFocus) {
      ContainerProps.onFocus(event);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      return;
    }

    if (isSubmenuFocused()) {
      event.stopPropagation();
    }

    const active = containerRef.current?.ownerDocument?.activeElement;

    if (event.key === "ArrowLeft" && isSubmenuFocused()) {
      containerRef.current?.focus();
    }

    if (
      event.key === "ArrowRight" &&
      event.target === containerRef.current &&
      event.target === active
    ) {
      const firstChild = menuContainerRef.current?.children[0];
      firstChild?.focus();
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  let tabIndex;
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <div
      {...ContainerProps}
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
    >
      <MenuItem
        {...MenuItemProps}
        data-open={!!open || undefined}
        className={className}
        ref={menuItemRef}
        keepopen={keepopen}
      >
        {label}
        <div style={{ flexGrow: 1 }} />
        {rightIcon}
      </MenuItem>
      <Menu
        hideBackdrop
        style={{ pointerEvents: "none" }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: rightAnchored ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: rightAnchored ? "right" : "left",
        }}
        css={customTheme}
        open={!!open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: "auto" }}>
          {children}
        </div>
      </Menu>
    </div>
  );
});

const SetFolderCell = memo(
  ({
    fileName,
    model,
    index,
    handleCheckboxChange,
    handleRightClick,
    visibleButtons,
    zoomLevel,
    sendMessageToSwift,
  }) => {
    // const isElectron = isElectronFunc();
    const containerStyle = {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: `${2 * zoomLevel}px ${8 * zoomLevel}px`,
      gap: 8 * zoomLevel,
    };

    function parseShotNumber(length) {
      length = length + 1;
      if (length < 10) {
        length = `0${length}`;
      }
      return length;
    }

    const handleSetFolder = (event) => {
      const shotFolderName = `S${parseShotNumber(index)}-${fileName}`;
      sendMessageToSwift(
        `createFolderWithShotNumber|${shotFolderName}|false|useExisting|${
          event.metaKey ? "setandbrowse" : "set"
        }`
      );
      // if (isElectron) {
      //   window.ipcRenderer?.send(
      //     "eval",
      //     `createFolderWithShotNumber("${shotFolderName}", false, "useExisting", "${
      //       event.metaKey ? "setandbrowse" : "set"
      //     }");`
      //   );
      // } else {
      //   alert("Folder creation is only available in ITS Connect.");
      // }
      handleCheckboxChange(model.id, "stillsShot", true);
    };

    const handleMakeFolder = (event) => {
      sendMessageToSwift(
        `createFolderWithShotNumber|${fileName}|true|createNew|${
          event.metaKey ? "setandbrowse" : "set"
        }`
      );
      handleCheckboxChange(model.id, "stillsShot", true);
    };

    const handleMakeFrontFolder = (event) => {
      const frontFolderName = `Front-${fileName}`;
      sendMessageToSwift(
        `createFolderWithShotNumber|${frontFolderName}|true|createNew|${
          event.metaKey ? "setandbrowse" : "set"
        }`
      );
      handleCheckboxChange(model.id, "stillsShot", true);
    };

    const handleMakeBackFolder = (event) => {
      const backFolderName = `Back-${fileName}`;
      sendMessageToSwift(
        `createFolderWithShotNumber|${backFolderName}|true|createNew|${
          event.metaKey ? "setandbrowse" : "set"
        }`
      );
      handleCheckboxChange(model.id, "stillsShot", true);
    };

    const handleMakeFrontBackFolder = (event) => {
      const frontFolderName = `Front-${fileName}`;
      const backFolderName = `Back-${fileName}`;
      sendMessageToSwift(
        `createFolderWithShotNumber|${frontFolderName}|true|createNew|set`
      );
      sendMessageToSwift(
        `createFolderWithShotNumber|${backFolderName}|true|createNew|create`
      );
      handleCheckboxChange(model.id, "stillsShot", true);
    };

    const buttonStyle = {
      fontSize: `${0.88 * zoomLevel}em`,
      width: `${120 * zoomLevel}px`,
    };

    return (
      <div
        style={containerStyle}
        onContextMenu={(event) => {
          handleRightClick(event, model);
        }}
      >
        {fileName && visibleButtons?.setFolder ? (
          <Button
            onClick={handleSetFolder}
            sx={buttonStyle}
            variant="contained"
            size="small"
          >
            Set Folder
          </Button>
        ) : null}
        {fileName && visibleButtons?.makeFolder ? (
          <Button
            onClick={handleMakeFolder}
            sx={buttonStyle}
            variant="contained"
            size="small"
          >
            Make Folder
          </Button>
        ) : null}
        {fileName && visibleButtons?.makeFrontBack ? (
          <Button
            onClick={handleMakeFrontBackFolder}
            sx={{ ...buttonStyle, fontSize: `${0.74 * zoomLevel}em` }}
            variant="contained"
            size="small"
          >
            Make Front & Back
          </Button>
        ) : null}
        {fileName && visibleButtons?.makeFront ? (
          <Button
            onClick={handleMakeFrontFolder}
            sx={buttonStyle}
            variant="contained"
            size="small"
          >
            Make Front
          </Button>
        ) : null}
        {fileName && visibleButtons?.makeBack ? (
          <Button
            onClick={handleMakeBackFolder}
            sx={buttonStyle}
            variant="contained"
            size="small"
          >
            Make Back
          </Button>
        ) : null}
      </div>
    );
  }
);

export default SetFolderCell;

export {
  FileNameCell,
  ImageReferenceCell,
  ShotCell,
  WrapTextCell,
  CheckboxCell,
  NestedMenuItem,
  SetFolderCell,
};

function isElectronFunc() {
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    window.process.type === "renderer"
  ) {
    return true;
  }

  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  ) {
    return true;
  }

  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    return true;
  }

  return false;
}
