import React, { useState, useCallback, useEffect } from "react";
import { use100vh } from "react-div-100vh";

const DEFAULT_LEFT_WIDTH = 60;

const ResizableLayout = ({ children }) => {
  const [leftWidth, setLeftWidth] = useState(() => {
    const stored = localStorage.getItem("image-grid-split-width");
    return stored ? parseFloat(stored) : DEFAULT_LEFT_WIDTH;
  });
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startLeftWidth, setStartLeftWidth] = useState(leftWidth);

  useEffect(() => {
    localStorage.setItem("image-grid-split-width", leftWidth.toString());
  }, [leftWidth]);

  const handleDragStart = useCallback(
    (clientX) => {
      setIsDragging(true);
      setStartX(clientX);
      setStartLeftWidth(leftWidth);
    },
    [leftWidth]
  );

  const handleDragMove = useCallback(
    (clientX) => {
      if (!isDragging) return;
      const containerWidth = window.innerWidth;
      const dx = clientX - startX;
      const newLeftWidth = startLeftWidth + (dx / containerWidth) * 98;
      if (newLeftWidth >= 10 && newLeftWidth <= 88) {
        setLeftWidth(newLeftWidth);
      }
    },
    [isDragging, startX, startLeftWidth]
  );

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();
      handleDragStart(e.clientX);
    },
    [handleDragStart]
  );

  const handleTouchStart = useCallback(
    (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      handleDragStart(touch.clientX);
    },
    [handleDragStart]
  );

  const handleTouchMove = useCallback(
    (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      handleDragMove(touch.clientX);
    },
    [handleDragMove]
  );

  const handleDoubleClick = useCallback(() => {
    setLeftWidth(DEFAULT_LEFT_WIDTH);
  }, []);

  useEffect(() => {
    if (isDragging) {
      const handleMouseMove = (e) => handleDragMove(e.clientX);
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleDragEnd);
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
      window.addEventListener("touchend", handleDragEnd);
      document.body.style.cursor = "col-resize";
      document.body.style.userSelect = "none";
      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleDragEnd);
        window.removeEventListener("touchmove", handleTouchMove);
        window.removeEventListener("touchend", handleDragEnd);
        document.body.style.cursor = "";
        document.body.style.userSelect = "";
      };
    }
  }, [isDragging, handleDragMove, handleDragEnd, handleTouchMove]);

  const rightWidth = 98 - leftWidth;

  const leftContent = React.cloneElement(children[0], {
    containerWidth: (window.innerWidth * leftWidth) / 98,
  });

  const rightContent = React.cloneElement(children[1], {
    containerWidth: (window.innerWidth * rightWidth) / 98,
  });

  const height = use100vh();

  return (
    <div
      style={{
        display: "flex",
        width: "99vw",
        height: height,
        position: "relative",
      }}
    >
      <div
        style={{
          width: `${leftWidth}vw`,
          overflow: "hidden",
          transition: isDragging ? "none" : "width 0.1s ease-out",
          marginRight: 6,
        }}
      >
        {leftContent}
      </div>
      <div
        style={{
          width: "12px",
          backgroundColor: isDragging ? "#2196F3" : "#666",
          cursor: "col-resize",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.2s ease",
          margin: "0 -4px",
          zIndex: 10,
          touchAction: "none",
        }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onDoubleClick={handleDoubleClick}
      >
        <div
          style={{
            width: "4px",
            height: "80px",
            backgroundColor: isDragging ? "#1976D2" : "#999",
            position: "absolute",
            transition: "background-color 0.2s ease",
            borderRadius: "2px",
          }}
        />
      </div>
      <div
        style={{
          width: `${rightWidth}vw`,
          overflow: "hidden",
          transition: isDragging ? "none" : "width 0.1s ease-out",
        }}
      >
        {rightContent}
      </div>
    </div>
  );
};

export default ResizableLayout;
