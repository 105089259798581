import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  CheckCircle,
  Image,
  Folder,
  Dashboard,
  SortByAlpha,
} from "@mui/icons-material";

export const InstructionsDialog = ({ open, onClose }) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    let timer;
    if (open) {
      setShowButton(false);
      timer = setTimeout(() => {
        setShowButton(true);
      }, 12000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick={() => null}
    >
      <DialogTitle>ITS Image Selects</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Typography paragraph>Here's how to make your selects:</Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              <ListItemText
                primary="Browse Folders"
                secondary="Click on folders to expand/collapse and view images"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Image />
              </ListItemIcon>
              <ListItemText
                primary="Select Images"
                secondary="Click on any image to add or remove it from your selects"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircle />
              </ListItemIcon>
              <ListItemText
                primary="Confirm Selection"
                secondary="Click 'Confirm Selects' when you're done, this will send your selection off to the production team to start retouching"
              />
            </ListItem>
            <Divider sx={{ marginTop: 3, marginBottom: 4 }} />
            <Typography paragraph>Important info:</Typography>
            <ListItem>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText
                primary="Storyboard Selects"
                secondary="The storyboard will automatically build as you make your selects"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SortByAlpha />
              </ListItemIcon>
              <ListItemText
                primary="Image Order"
                secondary="The images on the storyboard will be in the same order as the shot folders"
              />
            </ListItem>
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (showButton) {
              onClose();
            }
          }}
          variant="contained"
        >
          {showButton ? (
            "Got it"
          ) : (
            <>
              <CircularProgress size={20} color="inherit" />{" "}
              <span style={{ marginLeft: 20 }}>Loading...</span>
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ConfirmationDialog = ({
  open,
  onClose,
  selectedCount,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Confirm Selection</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have selected {selectedCount} images. Would you like to proceed
          with these selections?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ResetSelectsDialog = ({ open, onClose, onReset }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Reset</DialogTitle>
      <DialogContent>
        Are you sure you want to reset your selections?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onReset} color="error" variant="contained">
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
};
