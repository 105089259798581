import React from "react";
import {
    Tailwind,
    Html,
    Head,
    Body,
    Container,
    Img,
    Heading,
    Text,
    Section,
    Button,
    Hr,
    Preview
} from "@react-email/components";
import getJobName from "../../../GlobalFunctions/getJobName";

const SupplyEmail = ({ jobData,
    links,
}) => {
    console.log(links)
    return (
        <Html>
            <Head />
            <Preview>Your files are complete and ready for download.</Preview>
            <Tailwind>
                <Body className="bg-gray-100 font-sans">
                    <Container className="max-w-[540px] mx-auto bg-white p-10 rounded-xl shadow-md text-center my-10 drop-shadow-2xl">
                        <Section>
                            <Section className="mb-[40px]">
                                <Img
                                    src="https://www.imagesthatsell.com.au/ITS_LOGO_SMALL.png"
                                    alt="ITS Logo"
                                    width="120"
                                    className="mx-auto mb-[10px] mt-[40px]"
                                />
                            </Section>

                            {/* <Heading className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase">Elevating Your Visual Game</Heading> */}
                            {/* <Heading className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase">Framing Excellence</Heading> */}
                            {/* <Heading className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase">Precision in Every Pixel</Heading> */}
                            {/* <Heading className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase">Precision, Perfectly Framed</Heading> */}
                            {/* <Heading className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase">TRANSFORMING IDEAS INTO REALITY</Heading> */}
                            {/* <Heading className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase">YOUR VISION - OUR PASSION</Heading> */}
                            {/* <Heading className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase">BRINGING VISIONS TO LIFE</Heading> */}

                            {/* <Text className="text-[18px] text-gray-600 mb-[50px] text-transform: uppercase">YOUR FILES ARE READY!</Text> */}

                            <Heading className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase">THANK YOU SO MUCH</Heading>
                            <Text className="text-[18px] text-gray-600 mb-[50px] text-transform: uppercase">WE REALLY APPRECIATE YOUR BUSINESS</Text>

                            <Section className="bg-gray-100 rounded-lg mb-[50px] w-[95%]">
                                <Text className="text-gray-700 text-[14px]">Your latest job is complete:</Text>
                                <Heading className="text-[18px] pb-5 font-semibold text-gray-800">{getJobName(jobData)}</Heading>
                            </Section>

                            <Text className="text-gray-700 text-base leading-relaxed mb-[16px] mt-[20px] px-[4px]">
                                The files are currently uploading to Dropbox and should be available for download within the next hour.
                            </Text>

                            <Section className="text-center mt-[50px] mb-[55px]">
                                {links.map(link => (<Button href={link.url} className="bg-black text-white font-bold py-[16px] px-[32px] rounded-lg text-md hover:bg-gray-800 text-transform: uppercase">
                                    {link.label}
                                </Button>))}
                            </Section>

                            <Hr className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent my-[32px]" />

                            <Text className="text-gray-600 text-sm leading-loose px-[4px]">
                                Let us know if you have any trouble locating the files or if you have any questions.
                                <br />
                                <br />
                                Thank you for working with ITS
                            </Text>
                        </Section>
                    </Container>
                </Body>
            </Tailwind>
        </Html>
    );
};

export default SupplyEmail;
