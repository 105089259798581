import React, { useState, useEffect, useRef, useMemo } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { use100vh } from "react-div-100vh";

const ThumbnailManager = React.memo(({ selectedImages }) => {
  const loadedPaths = useRef(new Set());

  useEffect(() => {
    const preloadImages = async () => {
      const thumbnails = {};
      const newImages = selectedImages.filter(
        (img) => img.thumbnailUrl && !loadedPaths.current.has(img.path_lower)
      );

      if (newImages.length === 0) return;

      for (const image of newImages) {
        if (!loadedPaths.current.has(image.path_lower)) {
          await new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
              thumbnails[image.path_lower] = image.thumbnailUrl;
              loadedPaths.current.add(image.path_lower);
              resolve();
            };
            img.onerror = resolve;
            img.src = `data:image/jpeg;base64,${image.thumbnailUrl}`;
          });
        }
      }
    };

    preloadImages();
  }, [selectedImages]);

  return null;
});

const ZoomableCanvas = ({
  canvasRef,
  isZoomed,
  setIsZoomed,
  zoomOrigin,
  setZoomOrigin,
}) => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [dragged, setDragged] = useState(false);
  const dragSpeedFactor = 0.5; // Slow down drag speed

  useEffect(() => {
    if (!isZoomed) {
      setDragOffset({ x: 0, y: 0 });
    }
  }, [isZoomed]);

  const handleStart = (clientX, clientY) => {
    if (isZoomed) {
      setIsDragging(true);
      setDragged(false);
      setDragStart({
        x: clientX - dragOffset.x / dragSpeedFactor,
        y: clientY - dragOffset.y / dragSpeedFactor,
      });
    }
  };

  const handleMove = (clientX, clientY) => {
    if (isDragging && isZoomed) {
      const newX = (clientX - dragStart.x) * dragSpeedFactor;
      const newY = (clientY - dragStart.y) * dragSpeedFactor;
      setDragOffset({ x: newX, y: newY });
      setDragged(true);
    }
  };

  const handleEnd = () => {
    setIsDragging(false);
  };

  // Mouse Event Handlers
  const handleMouseDown = (e) => handleStart(e.clientX, e.clientY);
  const handleMouseMove = (e) => handleMove(e.clientX, e.clientY);
  const handleMouseUp = handleEnd;

  // Touch Event Handlers
  const handleTouchStart = (e) => {
    if (e.touches.length === 1) {
      e.preventDefault(); // Prevents scrolling
      handleStart(e.touches[0].clientX, e.touches[0].clientY);
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 1) {
      e.preventDefault(); // Prevents scrolling
      handleMove(e.touches[0].clientX, e.touches[0].clientY);
    }
  };

  const handleTouchEnd = handleEnd;

  const handleClick = (e) => {
    if (!dragged) {
      if (!isZoomed) {
        const rect = containerRef.current.getBoundingClientRect();
        setZoomOrigin({
          x: (e.clientX - rect.left) / rect.width,
          y: (e.clientY - rect.top) / rect.height,
        });
      }
      setIsZoomed(!isZoomed);
    }
  };

  const canvasStyle = {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: isZoomed ? "grab" : "zoom-in",
    transform: isZoomed
      ? `scale(2.3) translate(${dragOffset.x}px, ${dragOffset.y}px)`
      : "none",
    transformOrigin: isZoomed
      ? `${zoomOrigin.x * 100}% ${zoomOrigin.y * 100}%`
      : "center",
    transition: isDragging ? "none" : "transform 0.3s ease-out",
  };

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "calc(100% - 80px)",
        overflow: "hidden",
        position: "relative",
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={handleClick}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <canvas ref={canvasRef} style={canvasStyle} />
    </div>
  );
};

const SelectedImagesGrid = ({ selectedImages, jobName }) => {
  const canvasRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageBreaks, setPageBreaks] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomOrigin, setZoomOrigin] = useState({ x: 0.5, y: 0.5 });

  const pageData = useMemo(() => {
    const cols = 6;
    const rows = 6;
    const maxSlotsPerPage = cols * rows;
    let currentSlots = 0;
    let breaks = [];

    for (let i = 0; i < selectedImages.length; i++) {
      let slotsNeeded = 1;

      if (currentSlots + slotsNeeded > maxSlotsPerPage) {
        breaks.push(i);
        currentSlots = slotsNeeded;
      } else {
        currentSlots += slotsNeeded;
      }
    }

    if (
      breaks.length === 0 ||
      breaks[breaks.length - 1] !== selectedImages.length
    ) {
      breaks.push(selectedImages.length);
    }

    return breaks;
  }, [selectedImages]);

  useEffect(() => {
    setPageBreaks(pageData);
  }, [pageData]);

  const renderCanvas = useMemo(
    () => async () => {
      if (!canvasRef.current) return;

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      canvas.width = 4200;
      canvas.height = 6000;
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const cols = 6;
      const rows = 6;
      const padding = 90;
      const outerPadding = 100;
      const availableWidth =
        canvas.width - 2 * outerPadding - padding * (cols - 1);
      const cellWidth = availableWidth / cols;
      const cellHeight = cellWidth * 1.5;

      const startIdx = currentPage === 0 ? 0 : pageBreaks[currentPage - 1];
      const endIdx = pageBreaks[currentPage];
      const pageImages = selectedImages.slice(startIdx, endIdx);

      const occupiedCells = new Set();

      const findNextPosition = (isWide) => {
        for (let row = 0; row < rows; row++) {
          for (let col = 0; col < cols; col++) {
            if (!occupiedCells.has(`${row},${col}`)) {
              if (!isWide) {
                return { row, col };
              }
              if (col < cols - 1 && !occupiedCells.has(`${row},${col + 1}`)) {
                return { row, col };
              }
            }
          }
        }
        return null;
      };

      for (const image of pageImages) {
        if (image.thumbnailUrl) {
          const img = await new Promise((resolve) => {
            const imgEl = new Image();
            imgEl.onload = () => resolve(imgEl);
            imgEl.onerror = () => resolve(null);
            imgEl.src = `data:image/jpeg;base64,${image.thumbnailUrl}`;
          });

          if (!img) continue;

          const imgAspect = img.width / img.height;
          const isWide = imgAspect > 1;
          const position = findNextPosition(isWide);

          if (!position) continue;

          const { row, col } = position;

          if (isWide && col < cols - 1) {
            occupiedCells.add(`${row},${col}`);
            occupiedCells.add(`${row},${col + 1}`);

            const x = outerPadding + col * (cellWidth + padding);
            const y = outerPadding + row * (cellHeight + padding);
            const doubleCellWidth = cellWidth * 2 + padding;

            let drawWidth = doubleCellWidth;
            let drawHeight = drawWidth / imgAspect;

            if (drawHeight > cellHeight) {
              drawHeight = cellHeight;
              drawWidth = drawHeight * imgAspect;
            }

            const drawX = x + (doubleCellWidth - drawWidth) / 2;
            const drawY = y + (cellHeight - drawHeight) / 2;

            ctx.drawImage(img, drawX, drawY, drawWidth, drawHeight);
          } else {
            occupiedCells.add(`${row},${col}`);

            const x = outerPadding + col * (cellWidth + padding);
            const y = outerPadding + row * (cellHeight + padding);

            const cellAspect = cellWidth / cellHeight;
            let drawWidth = cellWidth;
            let drawHeight = cellHeight;
            let drawX = x;
            let drawY = y;

            if (imgAspect > cellAspect) {
              drawHeight = drawWidth / imgAspect;
              drawY += (cellHeight - drawHeight) / 2;
            } else {
              drawWidth = drawHeight * imgAspect;
              drawX += (cellWidth - drawWidth) / 2;
            }

            ctx.drawImage(img, drawX, drawY, drawWidth, drawHeight);
          }
        } else {
          const position = findNextPosition(false);
          if (!position) continue;

          const { row, col } = position;
          occupiedCells.add(`${row},${col}`);

          const x = outerPadding + col * (cellWidth + padding);
          const y = outerPadding + row * (cellHeight + padding);

          ctx.fillStyle = "#333";
          ctx.fillRect(x, y, cellWidth, cellHeight);
        }
      }
    },
    [currentPage, pageBreaks, selectedImages]
  );

  useEffect(() => {
    renderCanvas();
  }, [renderCanvas]);

  const totalPages = Math.max(1, pageBreaks.length);

  useEffect(() => {
    if (currentPage >= totalPages) {
      setCurrentPage(Math.max(0, totalPages - 1));
    }
  }, [totalPages, currentPage]);

  const saveCanvasAsJPEG = async () => {
    if (!canvasRef.current) return;
    setIsDownloading(true);
    const currentPageBackup = currentPage;
    const link = document.createElement("a");

    try {
      for (let page = 0; page < totalPages; page++) {
        setCurrentPage(page);
        await new Promise((resolve) => setTimeout(resolve, 500));

        const blob = await new Promise((resolve) => {
          canvasRef.current.toBlob(resolve, "image/jpeg", 0.95);
        });

        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = `${jobName}-Storyboard-${page + 1}.jpg`;
        link.click();
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error downloading storyboards:", error);
    } finally {
      setIsDownloading(false);
      setCurrentPage(currentPageBackup);
    }
  };

  const height = use100vh();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        padding: "10px",
        backgroundColor: "#f0f0f0",
        height: `calc(${height}px - 70px)`,
        position: "relative",
        marginTop: "64px",
      }}
    >
      <ThumbnailManager selectedImages={selectedImages} />
      <ZoomableCanvas
        canvasRef={canvasRef}
        isZoomed={isZoomed}
        setIsZoomed={setIsZoomed}
        zoomOrigin={zoomOrigin}
        setZoomOrigin={setZoomOrigin}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          padding: "10px",
          backgroundColor: "#ffffff",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          position: "absolute",
          bottom: "24px",
          left: "50%",
          transform: "translateX(-50%)",
          minHeight: "48px",
          maxHeight: "48px",
          width: "auto",
          maxWidth: "calc(100% - 48px)",
          overflow: "hidden",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setCurrentPage((p) => Math.max(0, p - 1))}
          disabled={currentPage === 0}
          startIcon={<NavigateBeforeIcon />}
          sx={{
            bgcolor: "#4CAF50",
            "&:hover": {
              bgcolor: "#45a049",
            },
            "&:disabled": {
              bgcolor: "#f0f0f0",
            },
            minWidth: "85px",
            maxWidth: "85px",
            padding: "6px 12px",
            height: "36px",
          }}
        >
          Prev
        </Button>

        <span
          style={{
            fontWeight: 500,
            color: "#000",
            whiteSpace: "nowrap",
            minWidth: "60px",
            textAlign: "center",
          }}
        >
          {currentPage + 1} of {totalPages}
        </span>

        <Button
          variant="contained"
          onClick={() => setCurrentPage((p) => Math.min(totalPages - 1, p + 1))}
          disabled={currentPage === totalPages - 1}
          endIcon={<NavigateNextIcon />}
          sx={{
            bgcolor: "#4CAF50",
            "&:hover": {
              bgcolor: "#45a049",
            },
            "&:disabled": {
              bgcolor: "#f0f0f0",
            },
            minWidth: "85px",
            maxWidth: "85px",
            padding: "6px 12px",
            height: "36px",
          }}
        >
          Next
        </Button>
        <Button
          variant="contained"
          onClick={saveCanvasAsJPEG}
          disabled={selectedImages.length === 0 || isDownloading}
          style={{
            backgroundColor: "#2196F3",
            marginLeft: "15px",
            minWidth: "160px",
            maxWidth: "160px",
            padding: "6px 12px",
            height: "36px",
          }}
        >
          {isDownloading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              Download
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(SelectedImagesGrid);
