import React, { useState, useEffect } from "react";
import { FixedSizeGrid } from "react-window";

export const ImageItem = ({ image, isSelected, onSelect }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const containerStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "#1a1a1a",
    position: "relative",
    borderRadius: "3px",
    outline: isSelected ? "3px solid #4CAF50" : "none",
    cursor: "pointer",
    backgroundImage: isLoaded && image.thumbnailUrl ? `url(data:image/jpeg;base64,${image.thumbnailUrl})` : "none",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  };

  useEffect(() => {
    if (image.thumbnailUrl) {
      const img = new Image();
      img.src = `data:image/jpeg;base64,${image.thumbnailUrl}`;
      img.onload = () => setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [image.thumbnailUrl]);

  return (
    <div
      id={`image-${image.path_lower}`}
      style={containerStyle}
      onClick={() => onSelect(image)}
    >
      {(!isLoaded || !image.thumbnailUrl) && <LoadingPlaceholder />}
    </div>
  );
};

const LoadingPlaceholder = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#1a1a1a",
      color: "#fff",
      borderRadius: "3px",
    }}
  >
    Loading...
  </div>
);

const Cell = ({ columnIndex, rowIndex, style, data }) => {
  const { images, selectedImages, onImageSelect, columnCount } = data;
  const index = rowIndex * columnCount + columnIndex;
  const image = images[index];

  if (!image) return null;

  return (
    <div style={{ ...style, padding: 4 }}>
      <ImageItem
        image={image}
        isSelected={selectedImages.some((img) => img.name === image.name)}
        onSelect={onImageSelect}
      />
    </div>
  );
};

export const GridInAccordion = ({
  images,
  selectedImages,
  onImageSelect,
  gridColumns,
  dimensions,
  itemSize,
}) => {
  const containerRef = React.useRef(null);
  const [gridWidth, setGridWidth] = React.useState(0);

  React.useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setGridWidth(rect.width);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    updateWidth();
    return () => resizeObserver.disconnect();
  }, []);

  const padding = 16;
  const computedWidth = gridWidth - padding;
  const columnWidth = Math.floor(computedWidth / gridColumns);
  const rowCount = Math.ceil(images.length / gridColumns);
  const totalHeight = rowCount * columnWidth;

  return (
    <div ref={containerRef} style={{ width: '100%', overflowX: 'hidden' }}>
      {gridWidth > 0 && (
        <FixedSizeGrid
          columnCount={gridColumns}
          columnWidth={columnWidth}
          height={totalHeight}
          rowCount={rowCount}
          rowHeight={columnWidth}
          width={computedWidth}
          itemData={{
            images,
            selectedImages,
            onImageSelect,
            columnCount: gridColumns,
          }}
        >
          {Cell}
        </FixedSizeGrid>
      )}
    </div>
  );
};