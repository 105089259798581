import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import ErrorIcon from "@mui/icons-material/Error";
import { Divider, MenuItem } from "@mui/material";

const EmailSelectorDialog = ({
  open,
  handleClose,
  jobData,
  setEmailModalOpen,
  setEmailKind,
  setOpenOperatorChecklistModal,
  firebase,
}) => {
  const [operatorChecklistComplete, setOperatorChecklistComplete] =
    useState(false);
  const [operatorChecklistText, setOperatorChecklistText] = useState(
    "Complete Operator Checklist"
  );

  useEffect(() => {
    if (jobData?.operatorChecklist) {
      let canSend = true;
      for (
        let i = 0;
        i < jobData?.operatorChecklist?.checklistItems?.length - 1;
        i++
      ) {
        const ci = jobData?.operatorChecklist.checklistItems[i];
        if (!ci.checked) {
          canSend = false;
          setOperatorChecklistText("Complete Operator Checklist");
        }
      }
      if (!jobData.imagesProcessedNumbers) {
        canSend = false;
        setOperatorChecklistText("Fill In Images Processed");
      }
      setOperatorChecklistComplete(canSend);
    }
  }, [jobData]);

  const lifeStoryboardButtons = [
    {
      icon: <SendIcon />,
      name: "Storyboards & Selects (Testing Don't Use)",
      click: async () => {
        handleClose();
        handleClose();
        setEmailModalOpen(true);
        setEmailKind("Storyboards & Selects");
      },
    },
    {
      icon: <SendIcon />,
      name: "Storyboards, Quickproofs & Selects",
      click: () => {
        handleClose();
        handleClose();
        setEmailModalOpen(true);
        setEmailKind("Storyboards, Quickproofs & Selects");
      },
    },
    {
      icon: <SendIcon />,
      name: "Storyboards & Quickproofs",
      click: () => {
        handleClose();
        handleClose();
        setEmailModalOpen(true);
        setEmailKind("Storyboards & Quickproofs");
      },
    },
    {
      icon: <SendIcon />,
      name: "Storyboards",
      click: () => {
        handleClose();
        handleClose();
        setEmailModalOpen(true);
        setEmailKind("Storyboards");
      },
    },
  ];

  const productStoryboardButtons = [
    {
      icon: <SendIcon />,
      name: "Product Storyboards - With Clients",
      click: () => {
        handleClose();
        handleClose();
        setEmailModalOpen(true);
        setEmailKind("Product Storyboards - With Clients");
      },
    },
    {
      icon: <SendIcon />,
      name: "Product Storyboards",
      click: () => {
        handleClose();
        handleClose();
        setEmailModalOpen(true);
        setEmailKind("Product Storyboards");
      },
    },
  ];

  const videoEmailButtons = [
    {
      icon: <SendIcon />,
      name: "Video Debrief",
      click: () => {
        handleClose();
        handleClose();
        setEmailModalOpen(true);
        setEmailKind("Video");
      },
    },
  ];

  const supplyEmailButtons = [
    {
      icon: <SendIcon />,
      name: "Supply",
      click: () => {
        handleClose();
        handleClose();
        setEmailModalOpen(true);
        setEmailKind("Supply");
      },
    },
    {
      icon: <SendIcon />,
      name: "COD",
      click: () => {
        handleClose();
        handleClose();
        setEmailModalOpen(true);
        setEmailKind("COD");
      },
    },
  ];

  let emailButtons = supplyEmailButtons;

  if (jobData?.jobTypeFull?.includes("-V")) {
    emailButtons = [...videoEmailButtons, ...emailButtons];
  } else if (jobData?.jobTypeFull?.includes("-P")) {
    emailButtons = [...productStoryboardButtons, ...emailButtons];
  } else if (operatorChecklistComplete) {
    if (jobData?.jobTypeFull?.includes("-L")) {
      emailButtons = [...lifeStoryboardButtons, ...emailButtons];
    }
  } else {
    emailButtons = [
      {
        icon: <ErrorIcon />,
        name: operatorChecklistText,
        click: () => {
          setOpenOperatorChecklistModal(true);
        },
      },
      ...emailButtons,
    ];
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div
        style={{
          width: 400,
          padding: "0px 20px 20px 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogTitle sx={{ marginBottom: 1 }}>Select Email</DialogTitle>
        {emailButtons.map((button, index) => {
          return (
            <span key={`emailItem_${index}`} style={{ width: 400 }}>
              <MenuItem onClick={button.click}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginLeft: 10, marginRight: 25 }}>
                    {button.name}
                  </div>
                  <div style={{ marginRight: 10, marginTop: 0 }}>
                    {button.icon}
                  </div>
                </div>
              </MenuItem>
              {index !== emailButtons.length - 1 ? <Divider /> : null}
            </span>
          );
        })}
      </div>
    </Dialog>
  );
};

export default EmailSelectorDialog;
