import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GridInAccordion } from "./Components";

const EnhancedAccordion = ({
  title,
  images,
  selectedImages,
  onImageSelect,
  gridColumns,
  dimensions,
  itemSize,
  backgroundColor = "#2a2a2a",
  onExpand,
  subfolders = null,
  containerWidth,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (isExpanded) {
      const imagesNeedingLinks = images.filter((img) => !img.directUrl);
      if (imagesNeedingLinks.length > 0) {
        onExpand(imagesNeedingLinks);
      }

      if (subfolders) {
        Object.values(subfolders).forEach((subfolder) => {
          const subfolderImagesNeedingLinks = subfolder.filter(
            (img) => !img.directUrl
          );
          if (subfolderImagesNeedingLinks.length > 0) {
            onExpand(subfolderImagesNeedingLinks);
          }
        });
      }
    }
  };

  const updatedDimensions = {
    ...dimensions,
    width: containerWidth || dimensions.width,
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={{
        unmountOnExit: true,
        timeout: 100,
      }}
      style={{
        backgroundColor: backgroundColor,
        color: "#fff",
        marginBottom: "8px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
      >
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span>{title}</span>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                const selectedInAccordion = selectedImages.filter((selected) =>
                  images.some((img) => img.name === selected.name)
                );
                if (selectedInAccordion.length > 0) {
                  onImageSelect(selectedInAccordion, true);
                }
              }}
              style={{
                visibility: selectedImages.some((selected) =>
                  images.some((img) => img.name === selected.name)
                )
                  ? "visible"
                  : "hidden",
              }}
            >
              Reset Selects
            </Button>
            <span>
              {
                selectedImages.filter((selected) =>
                  images.some((img) => img.name === selected.name)
                ).length
              }
              /{images.length} Selected
            </span>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          padding: "8px",
        }}
      >
        <GridInAccordion
          images={images}
          selectedImages={selectedImages}
          onImageSelect={onImageSelect}
          gridColumns={gridColumns}
          dimensions={updatedDimensions}
          itemSize={itemSize}
        />

        {subfolders &&
          Object.entries(subfolders).map(([subfolderName, subfolderImages]) => (
            <EnhancedAccordion
              key={subfolderName}
              title={subfolderName}
              images={subfolderImages}
              selectedImages={selectedImages}
              onImageSelect={onImageSelect}
              gridColumns={gridColumns}
              dimensions={updatedDimensions}
              itemSize={itemSize}
              backgroundColor="#3a3a3a"
              onExpand={onExpand}
              containerWidth={containerWidth}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default EnhancedAccordion;