import React, { useState, useEffect } from "react";

import "./emailModal.css";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import Popover from "@mui/material/Popover";

import LoadingFullScreen from "./LoadingFullScreen";
import SuccessFullScreen from "./SuccessFullScreen";
import ErrorFullScreen from "./ErrorFullScreen";
import MDEditor from "@uiw/react-md-editor";

import mdCommands from "./mdCommands";

import AllSupplyEmailContainer from "./Email Components/AllSupplyEmailContainer";

import EmailField from "./EmailField";

import { useAtom } from "jotai";
import { labelsAtom } from "../../-Atoms";

import { render } from "@react-email/components";
import DeleteIcon from "@mui/icons-material/Delete";

import getJobName from "../../GlobalFunctions/getJobName";

import {
  getDropboxFileRequest,
  getDropboxUrlWithRetry,
} from "../../DropboxFunctions";
import SupplyEmail from "./Email Components/SupplyEmail";

const { DateTime } = require("luxon");

const { v4: uuidv4 } = require("uuid");

function generateShortUUID() {
  return uuidv4()
    .replace(/-/g, "")
    .replace(/[^a-zA-Z0-9]/g, "")
    .substring(0, 12);
}

const EmailModal = ({
  firebase,
  theme,
  width,
  emailKind,
  jobData,
  clientData,
  setEmailModalOpen,
  isMobile,
}) => {
  const [labels] = useAtom(labelsAtom);

  const sendEmail = firebase.functions().httpsCallable("sendEmail");

  // eslint-disable-next-line
  const [error, setError] = useState(false);
  // eslint-disable-next-line
  const [errorReason, setErrorReason] = useState(
    "I'm not sure what went wrong here..."
  );
  const [toEmails, setToEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);
  const [replyToEmails, setReplyToEmails] = useState([]);
  const [ccVisible, setCcVisible] = useState(false);
  const [bccVisible, setBccVisible] = useState(false);
  const [subject, setSubject] = useState("");
  // eslint-disable-next-line
  const [attachments, setAttachments] = useState([]);
  const [fromEmail, setFromEmail] = useState("");
  const [selectsUrl, setSelectsUrl] = useState("");

  const [loadingVisible, setLoadingVisible] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [links, setLinksState] = useState([]);

  const ensureUniqueLinks = (links) => {
    const seen = new Map();
    return links.filter((link) => {
      const key = `${link.label}-${link.url}`;
      if (seen.has(key)) {
        return false;
      }
      seen.set(key, true);
      return true;
    });
  };

  const setLinks = (newLinks) => {
    const uniqueLinks = ensureUniqueLinks(newLinks);
    setLinksState(uniqueLinks);
    makeLinkButtons(uniqueLinks, setLinkButtons);
  };

  const [linkButtons, setLinkButtons] = useState("");

  const [previewText, setPreviewText] = useState("");

  useEffect(() => {
    makeLinkButtons(links, setLinkButtons);
  }, [links]);

  const makeLinkButtons = (links, setLinkButtons) => {
    let tmp = "";
    links.forEach((l) => {
      tmp = `${tmp}<a href="${l.url}" data-id="react-email-button" target="_blank" style="line-height: 100%; text-decoration: none; display: inline-block; max-width: 100%; padding: 12px 20px; background-color: rgb(0, 0, 0); border-radius: 0.25rem; color: rgb(255, 255, 255); font-size: 12px; font-weight: 600; text-align: center;">
  <span style="max-width: 100%; min-width: 185px; display: inline-block; line-height: 120%;">
    <span style="text-transform: uppercase;">${l.label}</span>
  </span>
</a>
<br>
<br>`;
    });
    setLinkButtons(tmp);
  };

  useEffect(() => {
    const main = async () => {
      if (emailKind === "Video") {
        setLoadingMessage("Getting Dropbox Link");
        setLoadingVisible(true);
        const path = `/${jobData.client}/${jobData.jobNumber}-${jobData.client}${jobData.jobTypeShort}`;
        const dropboxUrl = await getDropboxUrlWithRetry(path);
        let linkObj = {};
        if (dropboxUrl !== false) {
          linkObj = {
            label: "Click Here",
            url: dropboxUrl,
          };
          setLinks([...links, linkObj]);
        } else {
          alert("Unable to get dropbox link, please set it manually");
        }
        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (emailKind === "Supply") {
        setLoadingMessage("Getting Dropbox Link");
        setLoadingVisible(true);
        const path = `/${jobData.client}/${jobData.jobNumber}-${jobData.client}${jobData.jobTypeShort}`;
        const dropboxUrl = await getDropboxUrlWithRetry(path);
        let linkObj = {};
        if (dropboxUrl !== false) {
          linkObj = {
            label: "Download Files",
            // label: "Click Here",
            url: dropboxUrl,
          };
          setLinks([...links, linkObj]);
        } else {
          alert("Unable to get dropbox link, please set it manually");
        }
        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (
        emailKind === "Product Storyboards" ||
        emailKind === "Product Storyboards - With Clients"
      ) {
        setLoadingMessage("Getting Storyboards Dropbox Link");
        setLoadingVisible(true);
        const basePath = `/${jobData.client}/-Product Storyboards/${
          jobData.jobNumber
        }-${jobData.client}${jobData.jobTypeShort}${
          jobData.multipleDays ? `/${jobData.multipleDays}` : ``
        }`;

        const storyboardsUrl = await getDropboxUrlWithRetry(`${basePath}`);
        let linkObj = {};
        if (storyboardsUrl !== false) {
          linkObj = {
            label: "Product Storyboards",
            url: storyboardsUrl,
          };
        } else {
          alert("Unable to get storyboards link, please set it manually");
        }

        setLinks([...links, linkObj]);
        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (emailKind === "Storyboards") {
        setLoadingMessage("Getting Storyboards Dropbox Link");
        setLoadingVisible(true);
        const basePath = `/${jobData.client}/${jobData.jobNumber}-${
          jobData.client
        }${jobData.jobTypeShort}${
          jobData.multipleDays ? `/${jobData.multipleDays}` : ``
        }`;

        const storyboardsUrl = await getDropboxUrlWithRetry(
          `${basePath}/Storyboards`
        );
        let linkObj = {};
        if (storyboardsUrl !== false) {
          linkObj = {
            label: "Storyboards",
            url: storyboardsUrl,
          };
        } else {
          alert("Unable to get storyboards link, please set it manually");
        }

        const selectsUrl = await getDropboxUrlWithRetry(
          `${basePath}/Storyboard Selects`
        );
        let linkObj1 = {};
        if (selectsUrl !== false) {
          linkObj1 = {
            label: "Storyboard Selects",
            url: selectsUrl,
          };
        } else {
          alert(
            "Unable to get storyboard selects link, please set it manually"
          );
        }

        setLinks([...links, linkObj, linkObj1]);
        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (emailKind === "Storyboards & Quickproofs") {
        setLoadingMessage("Getting Storyboards Dropbox Link");
        setLoadingVisible(true);
        const basePath = `/${jobData.client}/${jobData.jobNumber}-${
          jobData.client
        }${jobData.jobTypeShort}${
          jobData.multipleDays ? `/${jobData.multipleDays}` : ``
        }`;

        const storyboardsUrl = await getDropboxUrlWithRetry(
          `${basePath}/Storyboards`
        );
        let linkObj1 = {};
        if (storyboardsUrl !== false) {
          linkObj1 = {
            label: "Storyboards",
            url: storyboardsUrl,
          };
        } else {
          alert("Unable to get storyboards link, please set it manually");
        }

        const selectsUrl = await getDropboxUrlWithRetry(
          `${basePath}/Storyboard Selects`
        );
        let linkObj3 = {};
        if (selectsUrl !== false) {
          linkObj3 = {
            label: "Storyboard Selects",
            url: selectsUrl,
          };
        } else {
          alert(
            "Unable to get storyboard selects link, please set it manually"
          );
        }

        setLoadingMessage("Getting Quickproofs Dropbox Link");
        const quickproofsUrl = await getDropboxUrlWithRetry(
          `${basePath}/Quickproofs`
        );
        let linkObj2 = {};
        if (quickproofsUrl !== false) {
          linkObj2 = {
            label: "Quickproofs",
            url: quickproofsUrl,
          };
        } else {
          alert("Unable to get quickproofs link, please set it manually");
        }

        setLinks([...links, linkObj1, linkObj3, linkObj2]);
        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (emailKind === "Storyboards, Quickproofs & Selects") {
        setLoadingMessage("Getting Storyboards Dropbox Link");
        setLoadingVisible(true);
        const basePath = `/${jobData.client}/${jobData.jobNumber}-${
          jobData.client
        }${jobData.jobTypeShort}${
          jobData.multipleDays ? `/${jobData.multipleDays}` : ``
        }`;

        getDropboxFileRequest(
          `${basePath}/Final Client Selects`,
          getJobName(jobData)
        );

        const storyboardsUrl = await getDropboxUrlWithRetry(
          `${basePath}/Storyboards`
        );
        let linkObj1 = {};
        if (storyboardsUrl !== false) {
          linkObj1 = {
            label: "Storyboards",
            url: storyboardsUrl,
          };
        } else {
          alert("Unable to get storyboards link, please set it manually");
        }

        const selectsUrl = await getDropboxUrlWithRetry(
          `${basePath}/Storyboard Selects`
        );
        let linkObj3 = {};
        if (selectsUrl !== false) {
          linkObj3 = {
            label: "Storyboard Selects",
            url: selectsUrl,
          };
        } else {
          alert(
            "Unable to get storyboard selects link, please set it manually"
          );
        }

        setLoadingMessage("Getting Quickproofs Dropbox Link");
        const quickproofsUrl = await getDropboxUrlWithRetry(
          `${basePath}/Quickproofs`
        );
        let linkObj2 = {};
        if (quickproofsUrl !== false) {
          linkObj2 = {
            label: "Quickproofs",
            url: quickproofsUrl,
          };
        } else {
          alert("Unable to get quickproofs link, please set it manually");
        }

        setLinks([...links, linkObj1, linkObj3, linkObj2]);

        setLoadingMessage("Getting Selects Dropbox Link");
        const selects = await getDropboxFileRequest(
          `${basePath}/Final Client Selects`,
          getJobName(jobData)
        );
        setSelectsUrl(selects);

        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (emailKind === "Storyboards & Selects") {
        setLoadingMessage("Getting Storyboards Dropbox Link");
        setLoadingVisible(true);
        const basePath = `/${jobData.client}/${jobData.jobNumber}-${
          jobData.client
        }${jobData.jobTypeShort}${
          jobData.multipleDays ? `/${jobData.multipleDays}` : ``
        }`;

        getDropboxFileRequest(
          `${basePath}/Final Client Selects`,
          getJobName(jobData)
        );

        const storyboardsUrl = await getDropboxUrlWithRetry(
          `${basePath}/Storyboards`
        );
        let linkObj1 = {};
        if (storyboardsUrl !== false) {
          linkObj1 = {
            label: "Storyboards",
            url: storyboardsUrl,
          };
        } else {
          alert("Unable to get storyboards link, please set it manually");
        }
        setLoadingMessage("Setting Up Selects Page");

        const clientSelectsRaw = await firebase
          .firestore()
          .collection("ClientSelects")
          .doc(jobData.id)
          .get();
        let clientSelectsDoc;

        let attestationID;
        if (clientSelectsRaw.exists) {
          clientSelectsDoc = clientSelectsRaw.data();
          attestationID = clientSelectsDoc.attestationID;
        } else {
          attestationID = generateShortUUID();
          await firebase
            .firestore()
            .collection("ClientSelects")
            .doc(jobData.id)
            .set({ attestationID, selectedImages: [] }, { merge: true });
        }
        const selectsPageUrl = `https://selects.imagesthatsell.com.au/${jobData.id}/${attestationID}`;

        let linkObj2 = {};
        if (storyboardsUrl !== false) {
          linkObj2 = {
            label: "Selects",
            url: selectsPageUrl,
          };
        }

        setLinks([...links, linkObj1, linkObj2]);
console.log([...links, linkObj1, linkObj2])
        setLoadingMessage("");
        setLoadingVisible(false);
      }
    };
    main();
    // eslint-disable-next-line
  }, []);

  const emailContainerMainStyle = {
    width: width,
    zIndex: 100,
  };
  const emailContainerStyle = {
    margin: 15,
    marginTop: 20,
    position: "relative",
  };
  const toggleCC = () => setCcVisible(!ccVisible);
  const toggleBCC = () => setBccVisible(!bccVisible);
  const getEmailsFromClientObjectArray = (arr) => {
    let tmpArr = [];

    arr.forEach((c) => {
      if (c) {
        if (c.email) {
          tmpArr.push(c.email);
        }
      }
    });
    return tmpArr;
  };

  const parseJobIndex = (kind) => {
    return parseInt(kind.replace(/Outsourcers.*-/, ""));
  };

  const [, setJobIndex] = useState(0);
  const [, setJobLetter] = useState("");

  const [thisJobsData, setThisJobsData] = useState({});
  const [outsourcerData, setOutsourcerData] = useState({});
  const addOrdinalSuffix = (n) => {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
  };
  const parseDueDate = () => {
    if (!thisJobsData.dueDate) return "7am Sydney Time";
    const dateObject = DateTime.fromISO(thisJobsData.dueDate).setZone(
      "Australia/Sydney"
    );
    const formattedDay = addOrdinalSuffix(dateObject.day);
    const formattedDate = dateObject.toFormat(`EEEE d'${formattedDay} 'MMMM`);
    return `${formattedDate}, 7am Sydney Time`;
  };
  const parseLineItems = () => {
    let linesData = "";
    thisJobsData?.lineItems?.forEach((line, index) => {
      let tmp;
      if (outsourcerData.companyName === "OMMS") {
        tmp = `${index + 1}. ${line.type} = ${line.images}\n\n`;
      } else {
        tmp = `${index + 1}. ${line.type} = ${line.images} @ $${
          line.price
        }\n\n`;
      }
      linesData = linesData + tmp;
    });

    return linesData;
  };

  const parseTotal = () => {
    let total = 0;
    thisJobsData?.lineItems?.forEach((line, index) => {
      let linePrice = parseInt(line.images) * parseFloat(line.price);
      total = total + linePrice;
    });
    return `$${parseFloat(total).toFixed(2)} AUD`;
  };

  useEffect(() => {
    const init = async () => {
      if (emailKind === "Video") {
        setFromEmail("production@imagesthatsell.com.au");
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        setSubject(getJobName(jobData));
        setToEmails([{ email: "adam@imagesthatsell.com.au" }]);
        setCcEmails([
          { email: "alex@imagesthatsell.com.au" },
          { email: "amy@imagesthatsell.com.au" },
          { email: "production@imagesthatsell.com.au" },
        ]);
      }

      if (emailKind === "Supply" || emailKind === "COD") {
        setFromEmail("production@imagesthatsell.com.au");
        if (jobData.client !== "Morrissey Media") {
          setCcEmails([{ email: "accounts@imagesthatsell.com.au" }]);
        }
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        setSubject(getJobName(jobData));
        setToEmails(
          // eslint-disable-next-line
          clientData.contacts.map((contact) => {
            if (contact.isSupply)
              return { name: contact.name, email: contact.email };
          })
        );
      }

      if (emailKind.includes("Outsourcers")) {
        let CCEmails = [];

        setFromEmail("production@imagesthatsell.com.au");
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        const jobIndexTmp = parseJobIndex(emailKind);
        const jobLetterTmp = numToLetter(jobIndexTmp + 1);
        const thisJobsDataTmp = jobData.outsourcers[jobIndexTmp];
        setJobIndex(jobIndexTmp);
        setJobLetter(jobLetterTmp);
        setThisJobsData(thisJobsDataTmp);

        const outsourcerDataRaw = await firebase
          .firestore()
          .collection("Outsourcers")
          .where("companyName", "==", thisJobsDataTmp.outsourcer)
          .get();
        const outsourcerDataTmp = outsourcerDataRaw.docs[0].data();
        setOutsourcerData(outsourcerDataTmp);

        if (jobLetterTmp === "A") setSubject(getJobName(jobData));
        else setSubject(`${getJobName(jobData)}-${jobLetterTmp}`);

        setToEmails([{ email: outsourcerDataTmp.mainEmail }]);
        outsourcerDataTmp.ccEmails.forEach((cc) => {
          CCEmails.push({ email: cc });
        });
        setCcEmails([...CCEmails]);

        const linkObj = {
          label: "Retouching Notes",
          url: `https://staff.imagesthatsell.com.au/outsourcerjob/${jobData.id}`,
        };
        setLinks([linkObj]);
      }
      if (
        emailKind === "Storyboards" ||
        emailKind === "Storyboards & Quickproofs" ||
        emailKind === "Storyboards, Quickproofs & Selects"
      ) {
        setFromEmail("production@imagesthatsell.com.au");
        if (jobData.client !== "Morrissey Media") {
          setCcEmails([
            { email: "adam@imagesthatsell.com.au" },
            { email: "bookings@imagesthatsell.com.au" },
          ]);
        }
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        setSubject(getJobName(jobData));
        setToEmails(
          // eslint-disable-next-line
          clientData.contacts.map((contact) => {
            if (contact.isSupply)
              return { name: contact.name, email: contact.email };
          })
        );
      }
      if (emailKind === "Product Storyboards") {
        setFromEmail("production@imagesthatsell.com.au");
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        setSubject(getJobName(jobData));
        setToEmails([{ email: "adam@imagesthatsell.com.au" }]);
      }
      if (emailKind === "Product Storyboards - With Clients") {
        setFromEmail("production@imagesthatsell.com.au");
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        setSubject(getJobName(jobData));
        const clientEmails = clientData.contacts.map((contact) => {
          if (contact.isSupply)
            return { name: contact.name, email: contact.email };
        });

        setToEmails([{ email: "adam@imagesthatsell.com.au" }, ...clientEmails]);
      }
    };
    init();
    // eslint-disable-next-line
  }, [emailKind]);

  const [addLinkButtonMenu, setAddLinkButtonMenu] = useState(null);
  const handleLinkButtonMenuClick = (event) => {
    setAddLinkButtonMenu(event.currentTarget);
  };
  const handleLinkButtonMenuClose = () => {
    setAddLinkButtonMenu(null);
  };

  const [newLinkLabel, setNewLinkLabel] = useState("");
  const [newLinkURL, setNewLinkURL] = useState("");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [emailMarkdown, setEmailMarkdown] = useState(``);

  const [markdownEditorOpen, setMarkdownEditorOpen] = useState(null);
  const handleMarkdownEditorClick = (event) => {
    if (!markdownEditorOpen) {
      setMarkdownEditorOpen(event.currentTarget);
    } else {
      handleMarkdownEditorClose();
    }
  };

  const handleMarkdownEditorClose = () => {
    setMarkdownEditorOpen(null);
  };

  // Email template configurations
  const EMAIL_TEMPLATES = {
    Supply: {
      previewText: "Your files are complete and ready for download.",
      getTemplate: (subject) => `# THANK YOU SO MUCH
#### WE REALLY APPRECIATE YOUR BUSINESS

Your latest job is complete:
### ${subject}

The files are currently uploading to Dropbox and should be available for download within the next hour.
Please find the direct link below:


{{links}}


Let us know if you have any trouble locating the files or if you have any questions at all.`,
    },

    COD: {
      previewText: "Please send through remittance for immediate supply.",
      getTemplate: (subject) => `# YOUR FILES ARE READY!
Your latest job is complete:
### ${subject}

Accounts will supply you with your invoice shortly.

For immediate file supply please email a remittance to production@imagesthatsell.com.au.`,
    },

    Video: {
      getPreviewText: (subject) => subject,
      getTemplate: (subject, jobData, userData) => {
        const videoBreakdown = jobData?.imageBreakdown?.find((obj) =>
          obj.description.includes("Videos")
        );
        const totalVideos = videoBreakdown?.quantityProcessed || 0;
        const editedVideos = videoBreakdown?.filesInPost || 0;
        const staffName =
          userData?.staffData?.preferredName || userData?.staffData?.firstName;

        return `Hi Adam,

We did ${totalVideos} videos for the **${subject}** job today,

${editedVideos} of ${totalVideos} have been edited.

It should take **REPLACE ME** hours to complete the edits.


{{links}}


Thanks,
      
${staffName}`;
      },
    },
  };

  const OUTSOURCER_TEMPLATES = {
    withRetouchingNotes: {
      previewText: "Your next job with ITS is ready to download.",
      getTemplate: ({
        outsourcerData,
        subject,
        thisJobsData,
        parseLineItems,
        parseTotal,
        parseDueDate,
      }) =>
        `Hi ${outsourcerData.contactName},

Our latest job is: **${subject}**

Please follow all instructions, these can be found either:

**Inside the job folder**

**Written on the images**

**In the retouching notes below**

<colour #F23620>**Please see below the retouching notes for this job, check them off as you go along.**</colour>

<colour #F23620>**If there is a little image icon next to the note, click it to open a reference image for that note.**</colour>


{{links}}


IMAGES:

${parseLineItems()}

Job Total: ${parseTotal()}

Deadline: ${parseDueDate()}

Thanks,

${thisJobsData.staff}
`,
    },

    withoutRetouchingNotes: {
      previewText: "Your next job with ITS is ready to download.",
      getTemplate: ({
        outsourcerData,
        subject,
        thisJobsData,
        parseLineItems,
        parseTotal,
        parseDueDate,
      }) =>
        `Hi ${outsourcerData.contactName},

Our latest job is: **${subject}**

Please follow all instructions, these can be found either:

**Inside the job folder**

**Written on the images**

IMAGES:

${parseLineItems()}

Job Total: ${parseTotal()}

Deadline: ${parseDueDate()}

Thanks,

${thisJobsData.staff}
`,
    },
  };

  const getProductStoryboardTemplate = (
    jobData,
    clientData,
    getJobName,
    subject,
    includeSelects = false
  ) => {
    return {
      previewText: "Your Storyboards are ready.",
      template: `Hi Adam,


The storyboards from this job are ready:
### ${subject}

                                                                                                 
{{links}}
`,
    };
  };

  const getProductStoryboardWithClientsTemplate = (
    jobData,
    clientData,
    getJobName,
    subject,
    includeSelects = false
  ) => {
    return {
      previewText: "Your Storyboards are ready.",
      template: `### YOUR STORYBOARDS ARE READY!

The storyboards from your latest job are ready:
### ${subject}


{{links}}
`,
    };
  };

  const getStoryboardTemplate = (
    jobData,
    clientData,
    getJobName,
    subject,
    includeSelects = false
  ) => {
    if (jobData.noRetouching) {
      return {
        previewText: "Your Storyboards are ready.",
        template: `### YOUR STORYBOARDS ARE READY!

The storyboards from your latest job are ready:
### ${subject}

We have been instructed that this job does not require our post production service.

Therefore we will supply your files based on the specifications you have outlined with the team.


{{links}}
`,
      };
    }

    const getExtraText = () => {
      if (!jobData.dayType) return "";

      const { dayType, imagesProcessedNumbers } = jobData;
      const isFullDay = dayType === "Full Day";
      const rateImages = isFullDay
        ? clientData.fullDayRate?.images
        : clientData.halfDayRate?.images;

      if (imagesProcessedNumbers > rateImages) {
        return `out of an allocated **${rateImages} images**

<colour #F23620>Addtional files above your allocated file count are billed at $15++ per file. Please let us know if you require a quote prior to proceeding otherwise the post prodctuion will proceed.</colour>`;
      }
      return "";
    };

    return {
      previewText: "Your Storyboards are ready.",
      template: `### YOUR STORYBOARDS ARE READY!
        
The storyboards from your latest job are ready:
### ${getJobName(jobData)}

${
  jobData.imagesProcessedNumbers
    ? `The file count for this shoot is: 

**${jobData.imagesProcessedNumbers} images** ${getExtraText()}

`
    : ""
}
This email is confirmation that you are happy to proceed with all of the files in the Storyboard.
Therefore, post production will now begin working on your files in order to meet your deadline.

Please email [production@imagesthatsell.com.au](mailto:production@imagesthatsell.com.au) immediately if you wish to reselect images or reduce the shot count.

Reselecting or adding images will put your job on hold until the selects are confirmed.
Your job going on hold will affect the final supply date. The post production team will inform you of the updated due date.

Fees apply.


{{links}}


${includeSelects ? "" : "If you have any questions, please let us know."}`,
    };
  };

  // Replace the existing useEffect with this:
  useEffect(() => {
    if (!emailKind || !subject) return;
    console.log(emailKind);
    // Handle basic email templates
    if (EMAIL_TEMPLATES[emailKind]) {
      const template = EMAIL_TEMPLATES[emailKind];
      setPreviewText(
        typeof template.getPreviewText === "function"
          ? template.getPreviewText(subject)
          : template.previewText
      );

      if (emailKind === "Video") {
        setEmailMarkdown(
          template.getTemplate(subject, jobData, firebase.auth().currentUser)
        );
      } else {
        setEmailMarkdown(template.getTemplate(subject));
      }
      return;
    }

    // Handle outsourcer templates
    if (emailKind.includes("Outsourcers")) {
      const templateType = emailKind.includes("includeRetouchingNotes")
        ? "withRetouchingNotes"
        : "withoutRetouchingNotes";
      const template = OUTSOURCER_TEMPLATES[templateType];

      setPreviewText(template.previewText);
      setEmailMarkdown(
        template.getTemplate({
          outsourcerData,
          subject,
          thisJobsData,
          parseLineItems,
          parseTotal,
          parseDueDate,
        })
      );
      return;
    }

    // Handle storyboard templates
    if (emailKind.includes("Product Storyboards")) {
      const template = getProductStoryboardTemplate(
        jobData,
        clientData,
        getJobName,
        subject,
        false
      );
      setPreviewText(template.previewText);
      setEmailMarkdown(template.template);
    }
    if (emailKind.includes("Product Storyboards - With Clients")) {
      const template = getProductStoryboardWithClientsTemplate(
        jobData,
        clientData,
        getJobName,
        subject,
        false
      );
      setPreviewText(template.previewText);
      setEmailMarkdown(template.template);
    }

    if (emailKind.includes("Storyboards") && !emailKind.includes("Product")) {
      const isSelectsIncluded =
        emailKind === "Storyboards, Quickproofs & Selects";
      const template = getStoryboardTemplate(
        jobData,
        clientData,
        getJobName,
        subject,
        isSelectsIncluded
      );

      setPreviewText(template.previewText);
      setEmailMarkdown(template.template);
    }
    // eslint-disable-next-line
  }, [emailKind, subject]);

  return (
    <>
      {error ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            flexDirection: "column",
            padding: "50px",
          }}
        >
          <p>An error occurred</p>
          <p>{errorReason}</p>
        </div>
      ) : (
        <>
          <LoadingFullScreen
            loadingVisible={loadingVisible}
            loadingMessage={loadingMessage}
            theme={theme}
          />
          <SuccessFullScreen successVisible={successVisible} theme={theme} />
          <ErrorFullScreen
            errorVisible={errorVisible}
            errorMessage={errorMessage}
            theme={theme}
          />

          <Menu
            anchorEl={addLinkButtonMenu}
            open={Boolean(addLinkButtonMenu)}
            onClose={handleLinkButtonMenuClose}
          >
            <div
              style={{
                padding: 15,
                width: 320,
              }}
            >
              {links &&
                links.map((link, index) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <TextField
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                          label="Label"
                          size="small"
                          sx={{ margin: 1 }}
                          fullWidth={true}
                          inputProps={{ style: { fontSize: 12 } }}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          value={link.label}
                          onChange={(e) => {
                            links[index].label = e.target.value;
                            setLinks([...links]);
                          }}
                        />
                        <TextField
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                          label="URL"
                          size="small"
                          sx={{ margin: 1 }}
                          fullWidth={true}
                          inputProps={{ style: { fontSize: 12 } }}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          value={link.url}
                          onChange={(e) => {
                            links[index].url = e.target.value;
                            setLinks([...links]);
                          }}
                        />
                      </div>
                      <IconButton
                        onClick={() => {
                          links.splice(index, 1);
                          setLinks([...links]);
                        }}
                        sx={{ marginLeft: 2 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <Divider sx={{ marginBottom: 1.5, marginTop: 1.5 }} />
                  </>
                ))}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  label="Label"
                  size="small"
                  sx={{ margin: 1 }}
                  value={newLinkLabel}
                  onChange={(e) => {
                    setNewLinkLabel(e.target.value);
                  }}
                />
                <TextField
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  label="URL"
                  size="small"
                  sx={{ margin: 1 }}
                  value={newLinkURL}
                  onChange={(e) => {
                    setNewLinkURL(e.target.value);
                  }}
                />
                <Button
                  size="small"
                  variant="contained"
                  sx={{ margin: 1 }}
                  onClick={() => {
                    links.push({
                      label: newLinkLabel,
                      url: newLinkURL,
                    });
                    handleLinkButtonMenuClose();
                    setLinks(links);
                    makeLinkButtons(links, setLinkButtons);
                    forceUpdate();
                    setNewLinkLabel("");
                    setNewLinkURL("");
                  }}
                >
                  Add Link
                </Button>
                <Divider sx={{ marginBottom: 1.5, marginTop: 1.5 }} />

                <Button
                  size="small"
                  variant="contained"
                  sx={{ margin: 1 }}
                  color="error"
                  onClick={handleLinkButtonMenuClose}
                >
                  Close
                </Button>
              </div>
            </div>
          </Menu>
          <div
            style={{
              background: theme === "dark" ? "#1f1f1f" : "#ffffff",
              height: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div style={emailContainerMainStyle}>
              <div style={emailContainerStyle}>
                <EmailField
                  emails={toEmails}
                  setEmails={setToEmails}
                  kind={"To"}
                  visible={true}
                  marginRight={20}
                />
                <Divider />
                <EmailField
                  emails={ccEmails}
                  setEmails={setCcEmails}
                  kind={"Cc"}
                  visible={ccVisible}
                  marginRight={0}
                />
                <Divider style={{ display: ccVisible ? "flex" : "none" }} />
                <EmailField
                  emails={bccEmails}
                  setEmails={setBccEmails}
                  kind={"Bcc"}
                  visible={bccVisible}
                  marginRight={0}
                />
                <Divider style={{ display: bccVisible ? "flex" : "none" }} />
                <div
                  style={{
                    width: "100%",
                    marginTop: 5,
                    marginBottom: 5,
                    display: "flex",
                    justifyContent: "space-between",

                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        marginTop: 5,
                        marginBottom: 5,
                        marginRight: 10,
                        fontSize: "0.8em",
                      }}
                    >
                      Subject:
                    </p>
                    <TextField
                      size="small"
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        sx: {
                          marginTop: "5px",
                          height: 20,
                          fontSize: "14px",
                        },
                      }}
                      InputProps={{ disableUnderline: true }}
                      value={subject || ""}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                  </div>
                  <ButtonGroup
                    size="small"
                    style={{ height: 24, marginRight: 25 }}
                  >
                    <Button
                      variant={ccVisible ? "contained" : "outlined"}
                      onClick={toggleCC}
                    >
                      CC
                    </Button>
                    <Button
                      variant={bccVisible ? "contained" : "outlined"}
                      onClick={toggleBCC}
                    >
                      BCC
                    </Button>
                  </ButtonGroup>
                </div>
                <Divider />
                <EmailField
                  emails={replyToEmails}
                  setEmails={setReplyToEmails}
                  kind={"Reply To"}
                  visible={true}
                  marginRight={0}
                />
                <Divider />
              </div>
            </div>
            <Fab
              color="primary"
              style={{
                width: "50px",
                height: "50px",
                zIndex: 200,
                position: "absolute",
                bottom: 14,
                right: 14,
              }}
              onClick={() => {
                let emailComponent = (
                  <AllSupplyEmailContainer
                    jobData={jobData}
                    editMode={true}
                    emailMarkdown={emailMarkdown}
                    setEmailMarkdown={setEmailMarkdown}
                    markdownEditorOpen={markdownEditorOpen}
                    handleMarkdownEditorClose={handleMarkdownEditorClose}
                    handleMarkdownEditorClick={handleMarkdownEditorClick}
                    Popover={Popover}
                    links={links}
                    linkButtons={linkButtons}
                    selectsUrl={selectsUrl}
                    previewText={previewText}
                    isMobile={isMobile}
                    emailKind={emailKind}
                  />
                );
                if (emailKind === "New Supply") {
                  emailComponent = (
                    <SupplyEmail
                      jobData={jobData}
                      editMode={true}
                      emailMarkdown={emailMarkdown}
                      setEmailMarkdown={setEmailMarkdown}
                      markdownEditorOpen={markdownEditorOpen}
                      handleMarkdownEditorClose={handleMarkdownEditorClose}
                      handleMarkdownEditorClick={handleMarkdownEditorClick}
                      Popover={Popover}
                      links={links}
                      linkButtons={linkButtons}
                      selectsUrl={selectsUrl}
                      previewText={previewText}
                      isMobile={isMobile}
                      emailKind={emailKind}
                    />
                  );
                }

                if (emailKind === "Supply") {
                  addLabelToCardFB(
                    firebase,
                    jobData,
                    "5e6881d6af988c41f2bf6e12"
                  );
                }
                if (emailKind === "COD") {
                  addLabelToCardFB(
                    firebase,
                    jobData,
                    "5f0e8644f6ee37295e76981e"
                  );
                }
                if (emailKind === "Video Email") {
                  addLabelToCardFB(
                    firebase,
                    jobData,
                    "482f6fba-69a6-4103-9c75-1eabcdffa6d5"
                  );
                }
                if (emailKind === "Storyboards") {
                  addLabelToCardFB(
                    firebase,
                    jobData,
                    "c9324864-f503-4267-a539-2c5accd94f7f"
                  );
                }
                if (emailKind === "Storyboards & Quickproofs") {
                  addLabelToCardFB(
                    firebase,
                    jobData,
                    "6c39be8a-a4dc-4fd6-880e-dd940849cb1a"
                  );
                }
                if (emailKind === "Storyboards, Quickproofs & Selects") {
                  addLabelToCardFB(
                    firebase,
                    jobData,
                    "1f990729-d969-49d6-b61c-e53b33b8fc43"
                  );
                }
                if (emailKind.includes("Outsourcers")) {
                  const jobIndexTmp = parseJobIndex(emailKind);
                  let labelToAdd = "";
                  labels.forEach((label) => {
                    if (
                      label.name.toLowerCase() ===
                      jobData.outsourcers[jobIndexTmp].outsourcer.toLowerCase()
                    ) {
                      labelToAdd = label.id;
                    }
                  });
                  addLabelToCardFB(firebase, jobData, labelToAdd);

                  jobData.outsourcers[jobIndexTmp].emailSent = true;
                  jobData.outsourcers[jobIndexTmp].sentDate = DateTime.now()
                    .setZone("Australia/Sydney")
                    .toUTC()
                    .toISO();
                  firebase
                    .firestore()
                    .collection("JobData")
                    .doc(jobData.id)
                    .update(
                      { outsourcers: jobData.outsourcers },
                      { merge: true }
                    )
                    .catch((error) => {
                      console.log(error);
                    });
                }

                const emailHtml = render(emailComponent);
                setLoadingVisible(true);
                sendEmail({
                  emailBody: emailHtml,
                  fromEmail,
                  toEmails: getEmailsFromClientObjectArray(toEmails),
                  ccEmails: getEmailsFromClientObjectArray(ccEmails),
                  bccEmails: getEmailsFromClientObjectArray(bccEmails),
                  replyToEmails: getEmailsFromClientObjectArray(replyToEmails),
                  subject,
                  attachments,
                }).then((res) => {
                  if (res.data.error) {
                    setLoadingVisible(false);
                    setErrorMessage(res.data.errortext);
                    setErrorVisible(true);
                  } else {
                    setLoadingVisible(false);
                    setSuccessVisible(true);
                    setEmailModalOpen(false);
                  }
                });
              }}
            >
              <SendIcon
                sx={{ marginLeft: "4px", width: "30px", height: "30px" }}
              />
            </Fab>
            <div
              style={{
                width: "100%",
                height: "72vh",
                marginTop: 0,
                position: "relative",
              }}
            >
              <div
                style={{
                  margin: 5,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ButtonGroup size="small">
                  <Button
                    variant={markdownEditorOpen ? "contained" : "outlined"}
                    onClick={handleMarkdownEditorClick}
                  >
                    Edit Email
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleLinkButtonMenuClick}
                  >
                    Edit Links
                  </Button>
                </ButtonGroup>
              </div>

              <div
                data-color-mode="light"
                style={{
                  display: markdownEditorOpen ? "block" : "none",
                  width: "100%",

                  border: "1px solid grey",
                  marginBottom: 5,
                }}
              >
                <MDEditor
                  preview="edit"
                  commands={mdCommands}
                  extraCommands={[]}
                  height="20vh"
                  value={emailMarkdown}
                  onChange={setEmailMarkdown}
                />
              </div>
              <div
                style={{
                  height: markdownEditorOpen ? "52vh" : "100%",
                  overflowY: "auto",
                }}
              >
                {emailKind === "New Supply" ? (
                  <div style={{ overflow: "auto" }}>
                    <SupplyEmail
                      jobData={jobData}
                      editMode={true}
                      links={links}
                    />
                  </div>
                ) : (
                  <AllSupplyEmailContainer
                    jobData={jobData}
                    editMode={true}
                    emailMarkdown={emailMarkdown}
                    setEmailMarkdown={setEmailMarkdown}
                    markdownEditorOpen={markdownEditorOpen}
                    handleMarkdownEditorClose={handleMarkdownEditorClose}
                    handleMarkdownEditorClick={handleMarkdownEditorClick}
                    Popover={Popover}
                    links={links}
                    linkButtons={linkButtons}
                    selectsUrl={selectsUrl}
                    previewText={previewText}
                    isMobile={isMobile}
                    emailKind={emailKind}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default EmailModal;

const addLabelToCardFB = async (firebase, jobData, labelId) => {
  if (jobData.idLabelsPermanent) {
    if (!jobData.idLabelsPermanent.includes(labelId)) {
      jobData.idLabelsPermanent.push(labelId);
    }
  } else {
    jobData.idLabelsPermanent = [labelId];
  }
  const noDupes = [...new Set(jobData.idLabelsPermanent)];
  jobData.idLabelsPermanent = noDupes;

  firebase
    .firestore()
    .collection("JobData")
    .doc(jobData.id)
    .update({ idLabelsPermanent: jobData.idLabelsPermanent });
};

function numToLetter(num) {
  let s = "",
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || undefined;
}
