// import React from "react";
// import * as React from "react";
// import { saveAs } from "file-saver";

// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Masonry from "@mui/lab/Masonry";
// import { styled } from "@mui/material/styles";
// const Label = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(0.5),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   borderBottomLeftRadius: 0,
//   borderBottomRightRadius: 0,
// }));
// const itemData = [
//   {
//     img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
//     title: "Fern",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627308595229-7830a5c91f9f",
//     title: "Snacks",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
//     title: "Mushrooms",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1529655683826-aba9b3e77383",
//     title: "Tower",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
//     title: "Sea star",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
//     title: "Honey",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
//     title: "Basketball",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//     title: "Breakfast",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d",
//     title: "Tree",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//     title: "Burger",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//     title: "Camera",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//     title: "Coffee",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627000086207-76eabf23aa2e",
//     title: "Camping Car",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
//     title: "Hats",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
//     title: "Tomato basil",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7",
//     title: "Mountain",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
//     title: "Bike",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
//     title: "Fern",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627308595229-7830a5c91f9f",
//     title: "Snacks",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
//     title: "Mushrooms",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1529655683826-aba9b3e77383",
//     title: "Tower",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
//     title: "Sea star",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
//     title: "Honey",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
//     title: "Basketball",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//     title: "Breakfast",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d",
//     title: "Tree",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//     title: "Burger",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//     title: "Camera",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//     title: "Coffee",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627000086207-76eabf23aa2e",
//     title: "Camping Car",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
//     title: "Hats",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
//     title: "Tomato basil",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7",
//     title: "Mountain",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
//     title: "Bike",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
//     title: "Fern",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627308595229-7830a5c91f9f",
//     title: "Snacks",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
//     title: "Mushrooms",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1529655683826-aba9b3e77383",
//     title: "Tower",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
//     title: "Sea star",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
//     title: "Honey",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
//     title: "Basketball",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//     title: "Breakfast",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d",
//     title: "Tree",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//     title: "Burger",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//     title: "Camera",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//     title: "Coffee",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627000086207-76eabf23aa2e",
//     title: "Camping Car",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
//     title: "Hats",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
//     title: "Tomato basil",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7",
//     title: "Mountain",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
//     title: "Bike",
//   },
// ];
// import {
//   listFolderContents,
// } from "./DropboxFunctions";
import ImageGrid from "./ClientSelects/ImageGrid";
// import { getAllFiles } from "./DropboxFunctions";

const Admin = ({ firebase }) => {
  // const [toDelete, setToDelete] = useState([]);
  const btnStyle = {
    padding: 5,
    margin: 5,
    border: "1px solid grey",
    borderRadius: 8,
  };

  // getAllFiles(
  //   "/Morrissey Media/259999-Morrissey Media-L/All QuickProofs",
  //   (files) => {
  //     console.log("Updated files:", files[0].url);
  //     // or update your UI here
  //   }
  // );
  return (
    <>
      <ImageGrid
        dropboxPath={"/Soleil Blue/25055-Soleil Blue-L"}
        jobName={"259999-Morrissey Media-L"}
      />
      {/* <ImageGrid dropboxPath={"/Morrissey Media/259999-Morrissey Media-L"} jobName={"259999-Morrissey Media-L"} /> */}

      {/* <ImageGrid path={"/Muma World/25071-Muma World-LC/All QuickProofs"} /> */}
      {/* <button
        style={btnStyle}
        onClick={async () => {
          console.log("listFolderContents")
          console.time("listFolderContents")
          const contents = await listFolderContents('/Muma World/25071-Muma World-LC/All QuickProofs');
          // const contents = await listFolderContents('/Muma World/25071-Muma World-LC/Storyboards');
          // contents.files.forEach(file => {
          //   console.log(file.thumbnail)
          // })
          // console.log('Total items:', contents.total);
          // console.log('Folders:', contents.folders);
          console.log('Files:', contents.files);
          console.timeEnd("listFolderContents")
        }}
      >
        Get Dropbox Folder Contents
      </button> */}

      {/* <Box sx={{ width: "100vw", minHeight: 829 }}>
        <Masonry columns={5} spacing={1}>
          {itemData.map((item, index) => (
            <div key={index}>
              <img
                srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                src={`${item.img}?w=162&auto=format`}
                alt={item.title}
                loading="lazy"
                style={{
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  display: "block",
                  width: "100%",
                }}
              />
            </div>
          ))}
        </Masonry>
      </Box> */}
      {/* <button
        style={btnStyle}
        onClick={async () => {
          console.log("Button Clicked");
          const createSlackChannel = firebase
            .functions()
            .httpsCallable("createSlackChannel");
          const result = await createSlackChannel({ channelName: "test" });
          console.log(result);
        }}
      >
        Create Channel
      </button> */}
      {/* <button
        style={btnStyle}
        onClick={async () => {
          const contactsRef = firebase.firestore().collection("ClientDatabase");
          const querySnapshot = await contactsRef.get();
          console.log(querySnapshot.docs);
          let contactsArray = [];

          querySnapshot.docs.forEach((doc) => {
            const contacts = doc.data().contacts;
            contacts &&
              contacts.forEach((contact) => {
                contactsArray.push({
                  email: contact.email.toLowerCase(),
                  name: contact.name,
                });
              });
          });

          const uniqueContacts = [];
          const emailsSet = new Set();

          contactsArray.forEach((contact) => {
            if (!emailsSet.has(contact.email)) {
              emailsSet.add(contact.email);
              uniqueContacts.push(contact);
            }
          });

          const csvContent = uniqueContacts
            .map((contact) => `${contact.email},${contact.name}`)
            .join("\n");

          const csvHeaders = "Email,Name\n";
          const csvData = csvHeaders + csvContent;

          const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
          saveAs(blob, "contacts.csv");
        }}
      >
        Export Contacts CSV
      </button>
      <button
        style={btnStyle}
        onClick={async () => {
          const newOperatorChecklist = {
            name: "Operator Checklist",
            id: "operator_checklist",
            checklistItems: [
              {
                checked: false,
                title:
                  "Ensure the correct amount of images have been processed and recorded",
                id: "item 1",
              },
              {
                checked: false,
                title:
                  "Check notes for any details about supplying to clients e.g Storyboards, Quickproofs etc...",
                id: "item 2",
              },
              {
                checked: false,
                title: "Ensure the Storyboard is complete",
                id: "item 3",
              },
              {
                checked: false,
                title: "Process Quickproofs if required",
                id: "item 4",
              },
              {
                checked: false,
                title: "Put JPEGS / TIFFS on working jobs",
                id: "item 5",
              },
              {
                checked: false,
                title: "Backup to client / photographer drive",
                id: "item 6",
              },
              {
                checked: false,
                title:
                  "Backup to current archive (or client archive if they have one)",
                id: "item 7",
              },
              {
                checked: false,
                title: "Supply Storyboards",
                id: "item 8",
              },
              {
                checked: false,
                title: "Move card from Shooting to Prep to Send",
                id: "item 9",
              },
            ],
          };

          const querySnapshot = await firebase
            .firestore()
            .collection("JobData")
            .where("operatorChecklist.item1", "==", false)
            .get();

          querySnapshot.forEach(async (doc) => {
            const docRef = firebase
              .firestore()
              .collection("JobData")
              .doc(doc.id);
            await docRef.update({
              operatorChecklist: newOperatorChecklist,
            });
          });

          console.log("Documents updated successfully.");
        }}
      >
        fix operator checklist
      </button>
      <button
        style={btnStyle}
        onClick={async () => {
          console.log("click");
          const doc = await firebase
            .firestore()
            .collection("AdminCollection")
            .doc("labels")
            .get();
          const data = doc.data();
          const labels = data.labels.labels;
          data.labels = labels;
          console.log(data);
          await firebase
            .firestore()
            .collection("AdminCollection")
            .doc("labels")
            .update({ colours: data.colours, labels: data.labels });
          // docs.docs.forEach(async (doc) => {
          //   const data = doc.data();
          //   console.log(data);
          //   //   data.id = doc.id;
          //   //   await firebase
          //   //     .firestore()
          //   //     .collection("StaffData")
          //   //     .doc(data.id)
          //   //     .update({ showWeekends: false });
          //   //   console.log(data);
          // });
        }}
      >
        Save Labels
      </button>
      <button
        style={btnStyle}
        onClick={async () => {
          console.log("click");
          const docs = await firebase.firestore().collection("StaffData").get();
          docs.docs.forEach(async (doc) => {
            const data = doc.data();
            data.id = doc.id;
            await firebase
              .firestore()
              .collection("StaffData")
              .doc(data.id)
              .update({ showWeekends: false });
            console.log(data);
          });
        }}
      >
        add show weekends
      </button> */}
      {/* <button
        style={btnStyle}
        onClick={async () => {
          console.log("click");
          // const tmp = [];
          const docs = await firebase
            .firestore()
            .collection("JobData")
            // .where("imagesSuppliedNumber", "==", true)
            .get();
          let hasImagesSupplied = [];
          // console.log(docs.docs.length);
          docs.docs.forEach(async (doc) => {
            const data = doc.data();
            data.id = doc.id;
            let num = parseInt(data.imagesSupplied);

            if (data.imagesSupplied) {
              if (!isNaN(num) && /^\d+$/.test(data.imagesSupplied)) {
                if (!data.multipleDays) {
                  hasImagesSupplied.push(data);
                }
              }
            }
          });
          hasImagesSupplied.sort((a, b) => b.imagesSupplied - a.imagesSupplied);

          hasImagesSupplied.forEach((job) => {
            console.log(job.jobNumber, job.client, job.imagesSupplied);
          });
        }}
      >
        get most images supplied
      </button> */}
      {/* <button
        style={btnStyle}
        onClick={async () => {
          // const tmp = [];
          const docs = await firebase
            .firestore()
            .collection("RemoteLogin")
            // .where("notificationTomorrowsJob", "==", true)
            .get();
          docs.docs.forEach(async (doc) => {
            if (doc.id !== "-PLACEHOLDER") {
              await firebase
                .firestore()
                .collection("RemoteLogin")
                .doc(doc.id)
                .delete();
            }
            // data.id = doc.id;
            // console.log(data);
            // if (data.firstName) return;

            // tmp.push(data);
          });
          // tmp.forEach(async (d) => {
          //   if (!d.firstName) {
          //     console.log(d.id, d);
          //     await firebase
          //       .firestore()
          //       .collection("StaffData")
          //       .doc(d.id)
          //       .delete();
          //   }
          // });
          // console.log(tmp);
        }}
      >
        clear remotelogin
      </button> */}
      {/* <button
        style={btnStyle}
        onClick={async () => {
          console.log("getting job data");
          const jobsRaw = await firebase
            .firestore()
            .collection("JobData")
            .where("bookedStart", ">=", new Date().toISOString())
            .get();
          console.log(jobsRaw.docs.length);

          jobsRaw.docs.forEach(async (l) => {
            const data = l.data();
            if (!data.outsourcers[0].outsourcer) {
              data.outsourcers = [];
              // if (!data.idLabelsPermanent) {
              //   data.idLabelsPermanent = [];
              //   // if (l.id === "EWJiDLybPuHnDRhsCLW6") {
              //   // console.log(data);

              await firebase
                .firestore()
                .collection("JobData")
                .doc(l.id)
                .update(data);
              console.log(`updated: ${l.id}`);
              // } else {
              //   console.log(`skipped: ${l.id}`);
              // }
              // }
            }
          });
        }}
      >
        remove os def
      </button> */}
      {/* <button
        style={btnStyle}
        onClick={async () => {
          console.log("getting job data");
          const jobsRaw = await firebase
            .firestore()
            .collection("JobData")
            .get();
          console.log(jobsRaw.docs.length);

          jobsRaw.docs.forEach(async (l) => {
            const data = l.data();
            if (!data.idLabelsPermanent) {
              data.idLabelsPermanent = [];
              // if (l.id === "EWJiDLybPuHnDRhsCLW6") {
              // console.log(data);

              await firebase
                .firestore()
                .collection("JobData")
                .doc(l.id)
                .update(data);
              console.log(`updated: ${l.id}`);
            } else {
              console.log(`skipped: ${l.id}`);
            }
            // }
          });
        }}
      >
        add idlabelspermanent
      </button> */}
      {/* <button
        onClick={async () => {
          const jt = [
            {
              name: "Misc",
              id: "c4c72e92-8465-4d89-809c-dae00a504e7b",
              nameShort: "M",
            },
            {
              name: "Creative Life",
              id: "c9549c6a-4ce3-474b-948d-54c8823e5aa4",
              nameShort: "LC",
            },
            {
              name: "Creative Flatlay",
              id: "ebd01cca-5c03-4b68-93d3-03d34c2d4a10",
              nameShort: "PC",
            },
            {
              id: "555dff75-4fb5-42e1-931d-d168dfab7792",
              name: "Design",
              nameShort: "D",
            },
            {
              id: "28d05a32-b38c-4c6e-bc38-e069927ea268",
              name: "Life",
              nameShort: "L",
            },
            {
              name: "Location Shoot",
              id: "6e6b17d2-09ac-490b-90f0-317bac593854",
              nameShort: "L",
            },
            {
              nameShort: "P",
              id: "635dc18d-acb0-4cbe-a829-627a99ba7298",
              name: "Ghost",
            },
            {
              name: "Ghost/Flatlay",
              nameShort: "P",
              id: "683810dc-3f49-45b9-95c9-ad0aba002f4a",
            },
            {
              nameShort: "P",
              name: "Ghost/Product",
              id: "de217a19-b750-4482-bd40-8189ca1b15e6",
            },
            {
              nameShort: "P",
              id: "9e9c63cd-35bd-469b-bfd8-18e4de5d95bc",
              name: "Ghost/Jewellery",
            },
            {
              id: "a02eb7b4-370a-474e-a220-81a5e1a88a92",
              nameShort: "P",
              name: "Flatlay",
            },
            {
              id: "c055a55f-7688-4673-b80b-acdd3f33fa43",
              nameShort: "P",
              name: "Hanging Flatlay",
            },
            {
              id: "913bdfac-5bda-4f43-8ef2-736a8cea7a7b",
              name: "Jewellery",
              nameShort: "P",
            },
            {
              name: "Product",
              id: "034bf0ec-ca53-417f-ac15-52f8a60b9793",
              nameShort: "P",
            },
            {
              name: "Retouching",
              id: "7b4c5aaa-3f09-43a7-b28e-d0b3b01efdb5",
              nameShort: "R",
            },
            {
              id: "1f1c6252-2140-4d20-96db-6023cb65127b",
              name: "Video",
              nameShort: "V",
            },
            {
              id: "246de15b-c7d7-41b4-a7ee-1abeb69cb4dc",
              nameShort: "E",
              name: "Video Editing",
            },
          ];
          await firebase
            .firestore()
            .collection("AdminCollection")
            .doc("admin")
            .update({ jobTypes: jt });
        }}
      >update JTS</button> */}
      {/* <button
        style={btnStyle}
        onClick={async () => {
          const tmp = [];
          const docs = await firebase
            .firestore()
            .collection("StaffData")
            // .where("notificationTomorrowsJob", "==", true)
            .get();
          docs.docs.forEach(async (doc) => {
            const data = doc.data();
            data.id = doc.id;
            if (data.firstName) return;

            tmp.push(data);
          });
          tmp.forEach(async (d) => {
            if (!d.firstName) {
              console.log(d.id, d);
              await firebase
                .firestore()
                .collection("StaffData")
                .doc(d.id)
                .delete();
            }
          });
          console.log(tmp);
        }}
      >
        clear notificationTomorrowsJob
      </button> */}
      {/* <button
        onClick={async () => {
          // const jobsRaw = await firebase
          //   .firestore()
          //   .collection("JobData")
          //   .orderBy("jobNumber", "desc")
          //   .orderBy("jobName", "desc")
          //   .where("year", "==", new Date().getFullYear())
          //   .get();
          // const docs = [];
          // jobsRaw.docs.forEach((l) => {
          //   const data = l.data();
          //   if (data.jobName) {
          //     docs.push(l.id);
          //   }
          // });

          const listsRaw = await firebase
            .firestore()
            .collection("AdminCollection")
            .doc("lists")
            .get();
          const lists = listsRaw.data().lists;
          console.log(lists);
          // const items = [];
          // lists.forEach((l) => {
          //   l.items.forEach((i) => {
          //     items.push(i);
          //   });
          // });
          // console.log(docs);
          // console.log(items);
          // const arrayC = docs.filter((itemA) => !items.includes(itemA));

          // let paid = [];
          // jobsRaw.docs.forEach((l) => {
          //   const data = l.data();
          //   if (!data.idLabels.includes("5e6881d6af988c41f2bf6e11")) {
          //     if (data.idLabels.includes("5e6881d6af988c41f2bf6e12")) {
          //       paid.push(l.id);
          //     }
          //   }
          //   // console.log(data.idLabels);
          // });
          // console.log(paid);

          // arrayC.forEach((p) => {
          //   lists[13].items.push(p);
          // });

          // const noDupes = [...new Set(lists[13].items)];
          // lists[13].items = noDupes;

          // console.log(lists[13]);
          // firebase
          //   .firestore()
          //   .collection("AdminCollection")
          //   .doc("lists")
          //   .update({ lists: lists });
        }}
      >
        fucking hell
      </button> */}

      {/* <button
        onClick={async () => {
          const listsRaw = await firebase
            .firestore()
            .collection("AdminCollection")
            // .doc("lists")
            .limit(400)
            .get();
          let toDeleteTmp = [];
          listsRaw.docs.forEach((l) => {
            if (l.id !== "oldLists") {
              if (l.id !== "labels") {
                if (l.id !== "lists") {
                  toDeleteTmp.push(l.id);
                }
              }
            }
          });
          setToDelete(toDeleteTmp);
        }}
      >
        get all lists
      </button>
      <button
        onClick={async () => {
          console.log(toDelete);
        }}
      >
        Check To Delete
      </button>
      <button
        onClick={async () => {
          toDelete.forEach(async (td) => {
            if (td.includes(" - ")) {
              await firebase
                .firestore()
                .collection("AdminCollection")
                .doc(td)
                .delete();
            }
          });
        }}
      >
        delete stored
      </button> */}
      {/* <button
        onClick={async () => {
          // EWJiDLybPuHnDRhsCLW6
          const docRaw = await firebase
            .firestore()
            .collection("JobData")
            .doc("EWJiDLybPuHnDRhsCLW6")
            .get();
          const doc = docRaw.data();
          doc.checklists.forEach((cl) => {
            if (cl.name === "Retouching Notes") {
              cl.checklistItems.push({
                checked: x.checked,
                excluded: x.excluded,
                id: x.id,
                image: x.image,
                outsourcer: x.outsourcer,
                title: x.title,
              });
            }
          });
          console.log(doc.checklists);
        }}
      >
        ffs
      </button> */}
      {/* <button
        onClick={async () => {
          console.log("click");
          const reqyear = 2024;
          const jobDataQuery = await firebase
            .firestore()
            .collection("JobData")
            .orderBy("jobNumber", "desc")
            .where("jobNumber", ">=", 0)
            .where("year", "==", reqyear)
            .limit(20)
            .get();
          const year = reqyear.toString().substr(-2);
          let newNum = "000";
          // console.log(jobDataQuery.docs);
          if (jobDataQuery.docs.length > 0) {
            let theDoc;
            // let filteredDocs = jobDataQuery.docs.filter((doc) => {
            //   const data = doc.data();
            //   console.log(data.jobNumber.toString().substr(2))
            //   if (data.jobNumber.toString().substr(2) === year) {
            //     return true;
            //   } else return false;
            // });
            // console.log(filteredDocs.length);
            // console.log(jobDataQuery.docs.length);
            for (let i = 0; i < jobDataQuery.docs.length; i++) {
              if (jobDataQuery.docs[i].data().client === "Morrissey Media") {
                continue;
              } else if (jobDataQuery.docs[i].data().jobNumber) {
                console.log(
                  jobDataQuery.docs[i].data().jobNumber.toString().substr(0, 2)
                );
                if (
                  jobDataQuery.docs[i]
                    .data()
                    .jobNumber.toString()
                    .substr(0, 2) === year
                ) {
                  theDoc = jobDataQuery.docs[i];
                  break;
                }
              }
            }
            if (theDoc) {
              newNum =
                parseInt(theDoc.data().jobNumber.toString().substr(2)) + 1;
            }
          }

          let newFullNum;
          if (newNum > 999) {
            newFullNum = parseInt(`${year}${padInt(newNum, 4)}`);
          } else {
            newFullNum = parseInt(`${year}${padInt(newNum, 3)}`);
          }
          console.log(newFullNum);
        }}
      >
        get latest jobnumber
      </button> */}
      {/* <GlobalStyles /> */}
      {/* <div style={{ height: 700, width: "100vw" }}>
        <Workbook
          data={[{ name: "Sheet1", id: "ass" }]}
          onChange={(data) => {
            console.log(data);
          }}
        />
      </div> */}
    </>
  );
};

export default Admin;

// function padInt(value, padding) {
//   let zeroes = new Array(padding + 1).join("0");
//   return (zeroes + value).slice(-padding);
// }

// const slackApi = {
//   createChannel: async (token, channelName) => {
//     try {
//       const response = await fetch(
//         "https://slack.com/api/conversations.create",
//         {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             Accept: "application/json",
//             "Content-Type": "application/x-www-form-urlencoded",
//           },
//           body: `name=${channelName
//             .toLowerCase()
//             .replace(/[^a-z0-9-_]/g, "-")}`,
//         }
//       );

//       const data = await response.json();
//       if (!data.ok) throw new Error(data.error);
//       return data.channel;
//     } catch (error) {
//       throw error;
//     }
//   },

//   archiveChannel: async (token, channelId) => {
//     try {
//       const response = await fetch(
//         "https://slack.com/api/conversations.archive",
//         {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             Accept: "application/json",
//             "Content-Type": "application/x-www-form-urlencoded",
//           },
//           body: `channel=${channelId}`,
//         }
//       );

//       const data = await response.json();
//       if (!data.ok) throw new Error(data.error);
//       return true;
//     } catch (error) {
//       throw error;
//     }
//   },
// };
