import React, { useState, useEffect, useRef } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MDEditor from "@uiw/react-md-editor";
import { Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChecklistIcon from "@mui/icons-material/Checklist";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import ConfettiExplosion from "react-confetti-explosion";
import { v4 as uuidv4 } from "uuid";
import { createGlobalStyle } from "styled-components";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import useLocalStorage from "use-local-storage";

import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";

import Switch from "@mui/material/Switch";

import { useAtom } from "jotai";
import {
  isMobileBrowserAtom,
  themeAtom,
  currentStaffDataAtom,
  filterWhenCompletingAtom,
  groupWhenFilteringAtom,
} from "../../-Atoms";

import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";

const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

const Checklists = ({
  jobData,
  clientData,
  staffData,
  findStaff,
  firebase,
  isDarkMode,
  setUrlModalUrl,
  setURLModalOpen,
  idList,
}) => {
  const GlobalStyles = createGlobalStyle`
    .checklistItem {
      cursor:pointer;
    }
    .checklistItem:hover {
      background: rgba(0, 0, 0, 0.1);
      // border-radius: 5px;
    }
  `;
  const [checklists, setChecklists] = useState(jobData?.checklists || []);

  useEffect(() => {
    const init = async () => {
      if (jobData?.checklists) {
        setChecklists(jobData?.checklists);
      }
    };
    init();
  }, [jobData, firebase]);

  const onDragEnd = (e) => {
    let sourceChecklist;
    let destinationChecklist;
    checklists.forEach((cl) => {
      if (cl.id === e.source.droppableId) {
        sourceChecklist = cl;
      }
      if (cl.id === e.destination.droppableId) {
        destinationChecklist = cl;
      }
    });

    const sameList = e.source.droppableId === e.destination.droppableId;

    if (sameList) {
      const items = reorder(
        sameList
          ? sourceChecklist.checklistItems
          : destinationChecklist.checklistItems,
        e.source.index,
        e.destination.index
      );
      sourceChecklist.checklistItems = items;
    } else {
      const result = move(
        sourceChecklist.checklistItems,
        destinationChecklist.checklistItems,
        e.source,
        e.destination
      );
      destinationChecklist.checklistItems = result.destination;
      sourceChecklist.checklistItems = result.source;
    }
    setChecklists([...checklists]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ checklists: checklists }, { merge: true })
      .catch((e) => console.log(e));
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result["source"] = sourceClone;
    result["destination"] = destClone;

    return result;
  };

  const [checklistItemFontSize, setChecklistItemFontSize] = useLocalStorage(
    "checklistItemFontSize",
    1.0
  );
  return (
    <>
      {checklists.length === 0 || !checklists ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              firebase
                .firestore()
                .collection("JobData")
                .doc(jobData.id)
                .update(
                  {
                    checklists: [
                      {
                        name: "Colour Corrections",
                        id: uuidv4(),
                        checklistItems: [],
                      },
                      {
                        name: "Colour Changes",
                        id: uuidv4(),
                        checklistItems: [],
                      },
                      {
                        name: "Retouching Notes",
                        id: uuidv4(),
                        checklistItems: [],
                      },
                    ],
                  },
                  { merge: true }
                );
            }}
          >
            Make Checklists
          </Button>
        </div>
      ) : null}
      <DragDropContext onDragEnd={onDragEnd}>
        <GlobalStyles />
        <div style={{ marginLeft: 10, color: "black", width: "96%" }}>
          {/* <div style={{ color: "red", fontSize: "1.8em", margin: 10 }}>
          I'm a work in progress, please don't use me yet.
        </div> */}
          <div id="ChecklistsContainer" style={{ width: "100%" }}>
            {checklists &&
              checklists.map((checklist) => (
                <Checklist
                  firebase={firebase}
                  jobData={jobData}
                  key={checklist.id}
                  isDarkMode={isDarkMode}
                  checklist={checklist}
                  checklists={checklists}
                  setChecklists={setChecklists}
                  checklistItemFontSize={checklistItemFontSize}
                  setChecklistItemFontSize={setChecklistItemFontSize}
                  setUrlModalUrl={setUrlModalUrl}
                  setURLModalOpen={setURLModalOpen}
                  idList={idList}
                />
              ))}
          </div>
        </div>
      </DragDropContext>
    </>
  );
};

export default Checklists;

const Checklist = ({
  firebase,
  jobData,
  isDarkMode,
  checklist,
  checklists,
  setChecklists,
  checklistItemFontSize,
  setChecklistItemFontSize,
  setUrlModalUrl,
  setURLModalOpen,
  idList,
}) => {
  const [theme] = useAtom(themeAtom);

  const [showImages, setShowImages] = useState(false);

  const [percentage, setPercentage] = useState(0);
  const [isExploding, setIsExploding] = useState(false);

  const [hideComplete, setHideComplete] = useState(
    checklist.hideComplete || false
  );

  useEffect(() => {
    if (checklist?.checklistItems?.length > 0) {
      let partial = 0;
      checklist?.checklistItems?.forEach((ci) => {
        if (ci.checked) partial = partial + 1;
      });
      const p = calculatePercentage(partial, checklist.checklistItems.length);
      setPercentage(p);
    } else {
      setPercentage(0);
    }
    setHideComplete(checklist.hideComplete || false);
  }, [checklist]);

  function calculatePercentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  const AddNewListItemTextID = `${checklist.id}_AddNewCheckListItem_Title`;

  const [addNewItemActive, setAddNewItemActive] = useState(false);
  const [newTitle, setNewTitle] = useState("");

  const handleNewItemTitleChange = (e) => {
    setNewTitle(fixMarkdown(e.target.value));
  };
  const handleAddNewItemClick = () => {
    setAddNewItemActive(true);
  };
  const addChecklistItem = async () => {
    if (!checklist.checklistItems) checklist.checklistItems = [];
    checklist.checklistItems.push({
      id: uuidv4(),
      title: newTitle,
      checked: false,
    });
    setChecklists([...checklists]);
    setAddNewItemActive(false);

    await firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ checklists: checklists }, { merge: true })
      .catch((e) => console.log(e));
    setNewTitle("");
    handleAddNewItemClick();
  };
  const handleCancelClick = () => {
    setNewTitle("");
    setAddNewItemActive(false);
  };

  const [hiddenMenuAnchorEle, setHiddenMenuAnchorEle] = useState(null);
  const handleHiddenMenuClick = (event) => {
    setHiddenMenuAnchorEle(event.currentTarget);
  };
  const handleHiddenMenuClose = () => {
    setHiddenMenuAnchorEle(null);
  };

  const [contextMenu, setContextMenu] = useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    if (contextMenu) {
      setContextMenu(null);
    } else {
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
    }
  };
  const handleContextClose = () => {
    setContextMenu(null);
  };

  const [groupWhenFiltering, setGroupWhenFiltering] = useAtom(
    groupWhenFilteringAtom
  );
  const [filterWhenCompleting, setFilterWhenCompleting] = useAtom(
    filterWhenCompletingAtom
  );

  const [currentStaffData] = useAtom(currentStaffDataAtom);

  return (
    <div
      style={{
        display:
          idList === "5f081763cd97556d03075c86" &&
          checklist.name.includes("Checklist")
            ? "none"
            : "block",
      }}
    >
      <Menu
        anchorEl={hiddenMenuAnchorEle}
        open={Boolean(hiddenMenuAnchorEle)}
        onClose={handleHiddenMenuClose}
        PaperProps={{
          style: {
            background: isDarkMode ? "#262626" : "#e3e3e3",
          },
        }}
      >
        <div
          style={{
            width: 180,
            height: 120,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <TextField
            sx={{ width: 90 }}
            value={checklistItemFontSize}
            disabled
            label="Font Size"
          />
          <div>
            <Button
              variant="outlined"
              sx={{ marginRight: 1 }}
              onClick={() => {
                try {
                  setChecklistItemFontSize((prev) => {
                    const newNum = parseFloat(prev) + 0.1;
                    return parseFloat(newNum).toFixed(1);
                  });
                } catch (e) {
                  console.log("🤷🏼‍♂️");
                }
              }}
            >
              <AddIcon />
            </Button>
            <Button
              variant="outlined"
              sx={{ marginLeft: 1 }}
              onClick={() => {
                try {
                  setChecklistItemFontSize((prev) => {
                    const newNum = parseFloat(prev) - 0.1;
                    return parseFloat(newNum).toFixed(1);
                  });
                } catch (e) {
                  console.log("🤷🏼‍♂️");
                }
              }}
            >
              <RemoveIcon />
            </Button>
          </div>
        </div>
      </Menu>
      <Menu
        open={contextMenu !== null}
        onClose={handleContextClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        sx={{
          backgroundColor:
            theme === "dark"
              ? "rgba(10, 10, 10, 0.5)"
              : "rgba(240, 240, 240, 0.5)",
          borderRadius: 1,
        }}
      >
        <MenuItem
          onClick={() => {
            // handleContextClose();
            setFilterWhenCompleting(!filterWhenCompleting);
            firebase
              .firestore()
              .collection("StaffData")
              .doc(currentStaffData.id)
              .set(
                { filterWhenCompleting: !filterWhenCompleting },
                { merge: true }
              );
          }}
        >
          <span>
            <span style={{ fontSize: "0.7rem", marginRight: 10 }}>
              {filterWhenCompleting ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
            </span>
            Filter When Completing
          </span>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            // handleContextClose();
            setGroupWhenFiltering(!groupWhenFiltering);
            firebase
              .firestore()
              .collection("StaffData")
              .doc(currentStaffData.id)
              .set(
                { groupWhenFiltering: !groupWhenFiltering },
                { merge: true }
              );
          }}
        >
          <span>
            <span style={{ fontSize: "0.7rem", marginRight: 10 }}>
              {groupWhenFiltering ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
            </span>
            Group When Filtering
          </span>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            // handleContextClose();
            setHideComplete(!hideComplete);
            checklist.hideComplete = !hideComplete;
            firebase
              .firestore()
              .collection("JobData")
              .doc(jobData.id)
              .update({ checklists: checklists }, { merge: true })
              .catch((e) => console.log(e));
          }}
        >
          <span>
            <span style={{ fontSize: "0.7rem", marginRight: 10 }}>
              {hideComplete ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </span>
            Hide Complete
          </span>
        </MenuItem>
      </Menu>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={handleHiddenMenuClick}
        >
          <ChecklistIcon
            fontSize="small"
            sx={{
              color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
              marginLeft: "17px",
              marginRight: "12px",
            }}
          />
          <div
            style={{
              fontSize: "1.15rem",
              fontWeight: 800,
              color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
            }}
          >
            {checklist.name}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Switch
            checked={showImages}
            onChange={(e) => setShowImages(e.target.checked)}
            inputProps={{ "aria-label": "Show Images" }}
            size="small"
            sx={{ marginRight: 2 }}
          />
          <div
            style={{
              color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
              fontWeight: "bold",
              marginRight: 10,
              userSelect: "none",
              MozUserSelect: "none",
              WebkitUserSelect: "none",
              msUserSelect: "none",
            }}
          >
            {checklist?.checklistItems?.length}
          </div>
          <IconButton
            sx={{ marginRight: 2.5 }}
            onClick={() => {
              filterArray(
                checklist,
                checklists,
                groupWhenFiltering,
                firebase,
                jobData
              );
            }}
            onContextMenu={handleContextMenu}
          >
            <Badge color="primary" variant="dot" invisible={!hideComplete}>
              <FilterListIcon
                sx={{
                  color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
                }}
              />
            </Badge>
          </IconButton>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 17,
          marginRight: 30,
        }}
      >
        <span
          style={{
            color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
            fontWeight: 800,
            fontSize: "0.7rem",
          }}
        >
          {Math.trunc(percentage)}%
        </span>
        <div style={{ width: "90%" }}>
          <LinearProgress variant="determinate" value={percentage} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {isExploding && (
          <ConfettiExplosion
            particleCount={140}
            particleSize={15}
            height={"120vh"}
            duration={2200}
            zIndex={9999}
          />
        )}
      </div>
      <div>
        <List sx={{ width: "100%", paddingTop: "3px", paddingBottom: "3px" }}>
          <Droppable droppableId={checklist.id}>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {checklist.checklistItems &&
                  checklist.checklistItems.map((ci, index) =>
                    !hideComplete || !ci.checked ? (
                      <ChecklistItem
                        key={`${ci.title}_${index}_CheckListItem`}
                        firebase={firebase}
                        jobData={jobData}
                        checklist={checklist}
                        checklists={checklists}
                        setChecklists={setChecklists}
                        isDarkMode={isDarkMode}
                        index={index}
                        ci={ci}
                        setIsExploding={setIsExploding}
                        checklistItemFontSize={checklistItemFontSize}
                        setUrlModalUrl={setUrlModalUrl}
                        setURLModalOpen={setURLModalOpen}
                        showImages={showImages}
                      />
                    ) : null
                  )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </List>
        {addNewItemActive ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "92%",
              marginLeft: 17,
            }}
          >
            <textarea
              id={AddNewListItemTextID}
              onKeyPress={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  addChecklistItem();
                  return false;
                }
              }}
              onBlur={() => {
                if (newTitle !== "") {
                  addChecklistItem();
                } else {
                  setAddNewItemActive(false);
                }
                return false;
              }}
              value={newTitle}
              autoFocus
              placeholder="Add an item"
              onFocus={(e) => {
                e.target.setSelectionRange(
                  e.target.value.length,
                  e.target.value.length
                );
              }}
              style={{
                width: "100%",
                resize: "none",
                fontSize: "1em",
                outline: "none",
                borderRadius: 5,
                border: "none",
                padding: 6,
                color: isDarkMode ? "#ffffff" : "#000000",
                background: isDarkMode ? "#1c1c1c" : "#ffffff",
              }}
              onChange={handleNewItemTitleChange}
            ></textarea>
            <div
              style={{
                marginTop: 8,
                marginBottom: 20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  size="small"
                  variant="contained"
                  onClick={addChecklistItem}
                  sx={{ marginRight: 1 }}
                >
                  Add
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Button
            sx={{
              marginLeft: "17px",
              marginBottom: 5,
            }}
            size="small"
            variant="contained"
            onClick={handleAddNewItemClick}
          >
            Add an item
          </Button>
        )}
      </div>
      <div style={{ marginTop: 40 }}> </div>
    </div>
  );
};

const filterArray = (
  checklist,
  checklists,
  groupWhenFiltering,
  firebase,
  jobData
) => {
  let sortedArray = [];
  if (groupWhenFiltering) {
    const checklistItems = checklist.checklistItems;
    const checkedRaw = checklistItems.filter((i) => i.checked);
    const uncheckedRaw = checklistItems.filter((i) => !i.checked);
    const checked = alphanumericalSort(checkedRaw, "title");
    const unchecked = alphanumericalSort(uncheckedRaw, "title");
    sortedArray = [...checked, ...unchecked];
  } else {
    sortedArray = alphanumericalSort(checklist.checklistItems, "title");
  }
  checklist.checklistItems = sortedArray;
  firebase
    .firestore()
    .collection("JobData")
    .doc(jobData.id)
    .update({ checklists: checklists }, { merge: true })
    .catch((e) => console.log(e));
};

const ChecklistItem = ({
  isDarkMode,
  firebase,
  jobData,
  checklist,
  checklists,
  setChecklists,
  index,
  ci,
  setIsExploding,
  checklistItemFontSize,
  setUrlModalUrl,
  setURLModalOpen,
  showImages,
}) => {
  const [isMobileBrowser] = useAtom(isMobileBrowserAtom);
  const [theme] = useAtom(themeAtom);

  const [filterWhenCompleting] = useAtom(filterWhenCompletingAtom);
  const [groupWhenFiltering] = useAtom(groupWhenFilteringAtom);

  const [menuAnchorEle, setMenuAnchorEle] = useState(null);
  const handleClick = (event) => {
    setMenuAnchorEle(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEle(null);
  };

  const ListItemTextID = `${ci.title}_${index}_CheckListItem_Title`;
  const CheckboxID = `${ci.title}_${index}_CheckListItem_Checkbox`;
  const TextAreaID = `${ci.title}_${index}_CheckListItem_TextArea`;

  const [title, setTitle] = useState(ci.title.replace(/^\s+|\s+$/g, ""));
  const [checked, setChecked] = useState(ci.checked);
  const [editMode, setEditMode] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const toggleCheck = (e) => {
    if (editMode) return;
    const newChecked = !checked;
    setChecked(newChecked);
    ci.checked = newChecked;

    let totalChecked = 0;
    checklist.checklistItems.forEach((c) => {
      if (c.checked) totalChecked = totalChecked + 1;
    });
    if (totalChecked === checklist.checklistItems.length) {
      setIsExploding(true);
      setTimeout(() => {
        setIsExploding(false);
      }, 3000);
    }
    if (ci.outsourcer) ci.outsourcer = false;
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ checklists: checklists }, { merge: true })
      .catch((e) => console.log(e));

    if (filterWhenCompleting) {
      filterArray(checklist, checklists, groupWhenFiltering, firebase, jobData);
    }
  };
  const handleClose = () => {
    setTitle(ci.title.replace(/^\s+|\s+$/g, ""));
    setEditMode(false);
  };
  const handleSave = () => {
    setEditMode(false);
    ci.title = fixMarkdown(title);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ checklists: checklists }, { merge: true })
      .catch((e) => console.log(e));
  };
  const handleItemDelete = () => {
    handleMenuClose();
    checklist.checklistItems.splice(index, 1);
    setChecklists([...checklists]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ checklists: checklists }, { merge: true })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setTitle(ci.title.replace(/^\s+|\s+$/g, ""));
    setChecked(ci.checked);
  }, [ci]);

  const [showOptions, setShowOptions] = useState(isMobileBrowser);

  const [isUploading, setIsUploading] = useState(false);

  const fileUploadInput = useRef(null);

  const handleAttachImageClick = () => {
    handleMenuClose();
    fileUploadInput.current.click();
  };

  const handleAttachment = async (e) => {
    setIsUploading(true);
    const files = fileUploadInput.current.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let fileName = file.name;

      const random = uuidv4().slice(0, 5);
      const ext = fileName.match(/(\.[^.]+)$/)[1];
      const fnOrig = fileName.replace(ext, "");
      const newFn = `${fnOrig}_${random}${ext}`;
      fileName = newFn;

      await new Promise((r) => setTimeout(r, 100));

      if (file.size > 150000000) {
        alert("file must be under 150MB");
      } else {
        let url;
        const res = await dbx
          .filesUpload({
            path: `/-Job Attachments/${jobData.id}/checklist_items/${fileName}`,
            autorename: false,
            mode: "add",
            mute: false,
            contents: file,
          })
          .catch((e) => {
            setIsUploading(false);
            console.log(e);
          });

        if (res && res.result) {
          await dbx
            .sharingListSharedLinks({
              path: res.result.path_display,
              direct_only: true,
            })
            .then((data) => {
              url = data.result.links[0].url;
            })
            .catch(async (e) => {
              await dbx
                .sharingCreateSharedLinkWithSettings({
                  path: res.result.path_display,
                  settings: {
                    requested_visibility: "public",
                    audience: "public",
                    access: "viewer",
                  },
                })
                .then((data) => {
                  url = data.result.url;
                })
                .catch((e) => {
                  setIsUploading(false);
                  console.log(e);
                });
            });
        }

        if (url) {
          console.log(url);
          ci.image = url;
          await firebase
            .firestore()
            .collection("JobData")
            .doc(jobData.id)
            .update({ checklists: checklists }, { merge: true })
            .catch((e) => {
              setIsUploading(false);
              console.log(e);
            });
          setIsUploading(false);
        }
      }
    }
  };
  const handleDeleteImageClick = async () => {
    handleMenuClose();
    ci.image = "";
    await firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ checklists: checklists }, { merge: true })
      .catch((e) => {
        setIsUploading(false);
        console.log(e);
      });
  };
  const handleExcludeNoteToggle = async () => {
    if (ci.excluded) {
      ci.excluded = false;
    } else {
      ci.excluded = true;
    }

    await firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ checklists: checklists }, { merge: true })
      .catch((e) => {
        setIsUploading(false);
        console.log(e);
      });
  };

  const handleDuplicateNote = () => {
    handleMenuClose();
    const duplicatedItem = {
      ...ci,
      id: uuidv4(),
      checked: false // Reset checked state for the duplicate
    };
    checklist.checklistItems.splice(index + 1, 0, duplicatedItem);
    setChecklists([...checklists]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ checklists: checklists }, { merge: true })
      .catch((e) => console.log(e));
  };


  return (
    <>
      <Draggable key={ci.id} draggableId={ci.id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            data-color-mode={isDarkMode ? "dark" : "light"}
          >
            <ListItem
              disablePadding
              className="checklistItem"
              onMouseEnter={() => {
                setShowOptions(true);
              }}
              onMouseLeave={() => {
                if (isMobileBrowser) {
                  setShowOptions(true);
                } else {
                  setShowOptions(false);
                }
              }}
            >
              <Menu
                anchorEl={menuAnchorEle}
                open={Boolean(menuAnchorEle)}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    background: isDarkMode ? "#262626" : "#e3e3e3",
                  },
                }}
              >
                <MenuItem onClick={handleExcludeNoteToggle}>
                  <div style={{ display: "flex", gap: 10 }}>
                    {!ci.excluded ? (
                      <>
                        <CheckBoxOutlineBlankIcon />
                        Exclude Note
                      </>
                    ) : (
                      <>
                        <CheckBoxIcon />
                        Include Note
                      </>
                    )}
                  </div>
                </MenuItem>
                <MenuItem onClick={handleDuplicateNote}>
                  <div style={{ display: "flex", gap: 10 }}>
                    <ContentCopyIcon />
                    Duplicate Note
                  </div>
                </MenuItem>
                <MenuItem onClick={handleAttachImageClick}>
                  <div style={{ display: "flex", gap: 10 }}>
                    <ImageIcon />
                    Attach Image
                  </div>
                </MenuItem>
                {ci.image ? (
                  <MenuItem onClick={handleDeleteImageClick}>
                    <div style={{ display: "flex", gap: 10 }}>
                      <DeleteIcon />
                      Delete Image
                    </div>
                  </MenuItem>
                ) : null}
                <MenuItem onClick={handleItemDelete}>
                  <div style={{ display: "flex", gap: 10 }}>
                    <DeleteIcon />
                    Delete
                  </div>
                </MenuItem>
              </Menu>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: editMode ? "start" : "center",
                    width: "90%",
                    marginLeft: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: editMode ? "start" : "center",
                      width: "100%",
                      minHeight: 40,
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked}
                        onClick={toggleCheck}
                        disableRipple
                        id={CheckboxID}
                        size="small"
                      />
                    </ListItemIcon>
                    {editMode ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "100%",
                          marginTop: 5,
                          marginLeft: -25,
                        }}
                      >
                        <textarea
                          id={TextAreaID}
                          onKeyPress={(event) => {
                            if (event.key === "Enter" && !event.shiftKey) {
                              handleSave();
                              return false;
                            }
                          }}
                          value={title}
                          autoFocus
                          onFocus={(e) => {
                            e.target.setSelectionRange(
                              e.target.value.length,
                              e.target.value.length
                            );
                          }}
                          style={{
                            width: "100%",
                            resize: "none",
                            fontSize: `${checklistItemFontSize}em`,
                            outline: "none",
                            borderRadius: 5,
                            border: "none",
                            padding: 6,
                            minHeight: 80,
                            color: isDarkMode ? "#ffffff" : "#000000",
                            background: isDarkMode ? "#1c1c1c" : "#ffffff",
                          }}
                          onChange={handleTitleChange}
                        ></textarea>
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 5,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={handleSave}
                            >
                              Save
                            </Button>
                            <IconButton
                              size="small"
                              sx={{ marginLeft: 1 }}
                              onClick={handleClose}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </div>
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={handleClick}
                          >
                            <MoreHorizIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            setEditMode(true);
                          }}
                          id={ListItemTextID}
                          style={{
                            width: "90%",
                            marginLeft: -25,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          {ci.outsourcer ? (
                            <div
                              style={{
                                width: "100%",
                                background:
                                  theme === "dark" ? "#45a5e5CC" : "#61b8f2CC",
                                borderRadius: 4,
                                padding: 4,
                                marginTop: 2,
                                marginBottom: 2,
                              }}
                            >
                              <span
                                style={{
                                  padding: 4,
                                  color: "#f5f5f5",
                                  fontWeight: "bold",
                                }}
                              >
                                Outsourcer
                              </span>
                              <MDEditor.Markdown
                                source={`${
                                  ci.checked && !ci.outsourcer ? "~~" : ""
                                }${title}${
                                  ci.checked && !ci.outsourcer ? "~~" : ""
                                }`}
                                style={{
                                  background: "transparent",
                                  width: "90%",
                                  fontSize: `${checklistItemFontSize}em`,
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  color: ci.checked ? "#262626" : "#f5f5f5",
                                }}
                              />
                            </div>
                          ) : ci.excluded ? (
                            <div
                              style={{
                                width: "100%",
                                background:
                                  theme === "dark" ? "#e54545CC" : "#f26161CC",
                                borderRadius: 4,
                                padding: 4,
                                marginTop: 2,
                                marginBottom: 2,
                              }}
                            >
                              <span
                                style={{
                                  padding: 4,
                                  color: "#f5f5f5",
                                  fontWeight: "bold",
                                }}
                              >
                                Excluded
                              </span>
                              <MDEditor.Markdown
                                source={`${ci.checked ? "~~" : ""}${title}${
                                  ci.checked ? "~~" : ""
                                }`}
                                style={{
                                  background: "transparent",
                                  width: "90%",
                                  fontSize: `${checklistItemFontSize}em`,
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  color: ci.checked ? "#262626" : "#f5f5f5",
                                }}
                              />
                            </div>
                          ) : (
                            <MDEditor.Markdown
                              source={`${ci.checked ? "~~" : ""}${title}${
                                ci.checked ? "~~" : ""
                              }`}
                              style={{
                                background: "transparent",
                                width: "90%",
                                fontSize: `${checklistItemFontSize}em`,
                                paddingTop: 5,
                                paddingBottom: 5,
                                color: ci.checked ? "#808080" : "",
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {isUploading ? (
                    <div>
                      <CircularProgress
                        sx={{
                          marginTop: 1,
                          marginRight: !editMode && showOptions ? 5.8 : 1.5,
                        }}
                        size={18}
                      />
                    </div>
                  ) : null}
                  <div>
                    {ci.image && !isUploading ? (
                      <PopupState variant="popover" popupId="image-popover">
                        {(popupState) => (
                          <>
                            {showImages ? (
                              <img
                                {...bindHover(popupState)}
                                src={ci.image.replace("&dl=0", "&raw=1")}
                                alt={ci.title}
                                style={{
                                  maxHeight: 150,
                                  maxWidth: 120,
                                  marginRight:
                                    !editMode && showOptions ? 38 : 4,
                                  cursor: "pointer",
                                  borderRadius: "4px",
                                }}
                                onClick={() => {
                                  setUrlModalUrl(ci.image);
                                  setURLModalOpen(true);
                                }}
                              />
                            ) : (
                              <IconButton
                                {...bindHover(popupState)}
                                edge="end"
                                size="small"
                                sx={{
                                  marginRight:
                                    !editMode && showOptions ? 4.95 : 0.7,
                                }}
                                onClick={() => {
                                  setUrlModalUrl(ci.image);
                                  setURLModalOpen(true);
                                }}
                              >
                                <ImageIcon fontSize="small" />
                              </IconButton>
                            )}
                            <HoverPopover
                              {...bindPopover(popupState)}
                              slotProps={{
                                paper: { style: { padding: 10 } },
                              }}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                            >
                              <img
                                src={ci.image.replace("&dl=0", "&raw=1")}
                                alt={ci.title}
                                style={{
                                  width: "500px",
                                  height: "500px",
                                  objectFit: "contain",
                                }}
                              />
                            </HoverPopover>
                          </>
                        )}
                      </PopupState>
                    ) : null}
                  </div>

                  {!editMode && showOptions ? (
                    <IconButton
                      edge="end"
                      size="small"
                      sx={{ marginRight: -3, marginLeft: -5 }}
                      onClick={handleClick}
                    >
                      <MoreHorizIcon fontSize="small" />
                    </IconButton>
                  ) : null}
                  <input
                    type="file"
                    ref={fileUploadInput}
                    multiple={true}
                    style={{ display: "none" }}
                    onChange={handleAttachment}
                  />
                </div>
              </div>
            </ListItem>
          </div>
        )}
      </Draggable>
    </>
  );
};

function fixMarkdown(val) {
  val = val.replace("* ", "*");
  val = val.replace(" *", "*");
  val = val.replace(" `", "`");
  val = val.replace("` ", "`");
  val = val.replace(" _", "_");
  val = val.replace("_ ", "_");
  return val;
}

function alphanumericalSort(array, key) {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  // Create a shallow copy of the array
  const newArray = [...array];

  return newArray.sort((a, b) => collator.compare(a[key], b[key]));
}

// function sortByKey(array, key) {
//   // Create a shallow copy of the array
//   const newArray = [...array];

//   return newArray.sort((a, b) => {
//     const valueA = a[key].toLowerCase();
//     const valueB = b[key].toLowerCase();

//     if (valueA < valueB) {
//       return -1;
//     }
//     if (valueA > valueB) {
//       return 1;
//     }
//     return 0;
//   });
// }
